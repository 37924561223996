import React, { useEffect, useRef, useState } from "react";
import { RetellWebClient } from "retell-client-js-sdk";
import axios from "axios";
import { API_URL, retell_mode, retellai_id } from "../../config";
import scenegptEatingPopcorn from "../../assets/lotties/sceneGPT.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faPhoneSlash } from "@fortawesome/free-solid-svg-icons";
import AudioWaveform from "./AudioWaveform";
import log from "loglevel";
import * as Sentry from "@sentry/react";

const api = axios.create({
  baseURL: `${API_URL}/api`
});

const retellWebClient = new RetellWebClient();

const VoiceChat = () => {
  const [callStatus, setCallStatus] = useState("Idle");
  const [transcript, setTranscript] = useState("");
  const [response, setResponse] = useState("");
  const webSocketRef = useRef<WebSocket | null>(null);
  const retellWebClientRef = useRef<RetellWebClient>(retellWebClient);
  const [isAgentSpeaking, setIsAgentSpeaking] = useState(false);
  const [audioData, setAudioData] = useState<Float32Array>(new Float32Array());


  const startCall = async () => {
    try {
      let llmWebSocket;
      const res = await api.post("/create-web-call", {
        agent_id: retellai_id
      });
      const { access_token, call_id } = res.data;

      setCallStatus("Connecting");

      // Initialize the Retell Web Client with the access token
      await retellWebClientRef.current.startCall({
        accessToken: access_token,
        // sampleRate: 24000, // Optional: set sample rate of the audio capture and playback
        // captureDeviceId: "default", // Optional: device id of the mic
        // playbackDeviceId: "default", // Optional: device id of the speaker
        emitRawAudioSamples: true // Optional: Whether to emit "audio" events
      });

      setCallStatus("Active");

      // Set up event listeners
      retellWebClientRef.current.on("transcript", (data) => {
        setTranscript(data.transcript);
      });

      retellWebClientRef.current.on("call_ended", () => {
        setCallStatus("Ended");
      });

      // retellWebClientRef.current.on("agent_start_talking", () => {
      // });
      //
      // retellWebClientRef.current.on("agent_stop_talking", () => {
      // });

      retellWebClientRef.current.on("audio", (data: Float32Array) => {
        setAudioData(data);
      });

      retellWebClientRef.current.on("error", (error) => {
        retellWebClientRef.current.stopCall();
        setCallStatus("Error");
      });

      // Use the correct WebSocket URL for LLM connection
      if (retell_mode == "HOSTED") {
        llmWebSocket = new WebSocket(`wss://api.retellai.com/audio-websocket/${call_id}`);
      } else {
        llmWebSocket = new WebSocket(`wss://api.retellai.com/retell-llm-new/d0b4995247bffd5b81f1fb31e7c6b156`);
      }

      webSocketRef.current = llmWebSocket;

      // llmWebSocket.onopen = () => {
      //
      // };

      llmWebSocket.onmessage = (event: MessageEvent) => {
        if (event.data instanceof Blob) {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const data = JSON.parse(reader.result as string);
              setResponse(data);
            } catch (parseError) {

              Sentry.captureException(parseError);
              log.error("Failed to parse LLM message:", parseError);
            }
          };
          reader.readAsText(event.data);
        } else {
          try {
            const data = JSON.parse(event.data);
            setResponse(data);
          } catch (parseError) {
            Sentry.captureException(parseError);
            log.error("Failed to parse LLM message:", parseError);
          }
        }
      };

      // llmWebSocket.onclose = () => {
      //
      // };

    } catch (error) {
      Sentry.captureException(error);
      log.error("Failed to start call:", error);
      setCallStatus("Error");
    }
  };

  const endCall = () => {
    if (retellWebClientRef.current) {
      retellWebClientRef.current.stopCall();
    }
    if (webSocketRef.current) {
      webSocketRef.current.close();
    }
    setCallStatus("Ended");
  };

  useEffect(() => {
    return () => {
      if (webSocketRef.current) {
        webSocketRef.current.close();
      }
      if (retellWebClientRef.current) {
        retellWebClientRef.current.stopCall();
      }
    };
  }, []);

  const toggleConversation = async () => {
    log.log("toggleConversation");
  };

  return (
    <div>
      <div className="VoiceApp">
        <p className={"voice-chat-header"}>Scenester</p>

        <header className="VoiceApp-header">
          <div
            className={`portrait-container 
            ${callStatus === "active" ? "active" : ""} 
            ${callStatus === "inactive" ? "inactive" : ""} 
            ${isAgentSpeaking ? "agent-speaking" : ""}`}
            onClick={toggleConversation}
          >

            <img
              className="voice-character-avatar-large"
              src={scenegptEatingPopcorn}
              alt="SceneGPT eating popcorn"
            />
          </div>
          <AudioWaveform isAgentSpeaking={isAgentSpeaking} audioData={audioData} />
          <p>{callStatus}</p>
          <div className={"voice-call-buttons-container"}>
            <button className="call-button" onClick={startCall} disabled={callStatus !== "Idle"}>
              <FontAwesomeIcon icon={faPhone} />
            </button>
            <button className="end-call-button" onClick={endCall} disabled={callStatus !== "Active"}>
              <FontAwesomeIcon icon={faPhoneSlash} />
            </button>
          </div>
        </header>
      </div>
    </div>
  );
};

export default VoiceChat;