import React, { FC, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import posthog from "posthog-js";
import "../../assets/css/chatlanguagebtn.scss";
import CornerMaxButton from "../miscs/CornerMaxButton";

interface ChatLanguageProps {
  setChatLanguage: (language: string) => void;
}

const ChatLanguageButton: FC<ChatLanguageProps> = ({ setChatLanguage }) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);
  const languages = [
    "English",
    "Spanish",
    "French",
    "German",
    "Chinese",
    "Japanese"
  ];
  const listRef = useRef<HTMLUListElement>(null);

  const handleClick = (e: React.FormEvent) => {
    e.preventDefault();
    setIsActive(!isActive);
    if (listRef.current) {
      listRef.current.style.height = isActive ? "0" : "300px";
    }
  };

  const handleLanguageClick = (language: string) => {
    setChatLanguage(language);
    posthog.capture("language_selected", { language });
    setSelectedLanguage(language);
    setTimeout(() => {
      setIsActive(false);
    }, 300);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target && !target.closest(".language-list-container")) {
        setIsActive(false);
        if (listRef.current) {
          listRef.current.style.height = "300px";
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <React.Fragment>
      <div className={`language-list-container ${isActive ? "active" : ""} side-options-buttons`}>
        <CornerMaxButton blockIsActive={false} location="options-menu">
          <button
            className="language-more-button"
            aria-label="Menu Button"
            onClick={handleClick}
          >

            <FontAwesomeIcon
              icon={faGlobe}
              className="icon-shadow"
              style={{ color: "#f4f7fa" }}
            />{" "}

          </button>
        </CornerMaxButton>
        <ul className="language-more-button-list" ref={listRef} style={{
          display: isActive ? "block" : ""
        }}>
          {languages.map((language, index) => (
            <CornerMaxButton blockIsActive={true} key={index} location="options-item">
              <li
                key={index}
                className={`language-more-button-list-item ${
                  language === selectedLanguage ? "selected" : ""
                }`}
                onClick={() => handleLanguageClick(language)}
              >
                <span className={`emoji ${language.toLowerCase()}`}></span>
                <span>{language}</span>
              </li>
            </CornerMaxButton>
          ))}
        </ul>

      </div>
    </React.Fragment>
  );
};

export default ChatLanguageButton;
