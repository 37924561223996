import React, { FC, useEffect, useState } from "react";
import CornerMaxButton from "../miscs/CornerMaxButton";
import { Delete, Mic, MicOff, Public, Repeat, Settings } from "@mui/icons-material";

interface ChatMenuProps {
  toggleMicrophone: () => void;
  removeMessagesForCharacter: () => void;
  toggleFollowups: () => void;
  toggleWorldSim: () => void;
  microphoneEnabled: boolean;
  followupEnabled: boolean;
  currentCharacter: string;
  setTemporaryConfig: () => void;
}

const ChatMenuButton: FC<ChatMenuProps> = ({
                                             toggleMicrophone,
                                             removeMessagesForCharacter,
                                             toggleFollowups,
                                             toggleWorldSim,
                                             microphoneEnabled,
                                             followupEnabled,
                                             setTemporaryConfig,
                                             currentCharacter
                                           }) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target && !target.closest(".list-container")) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderMenuItems = () => {
    if (currentCharacter === "Scenester") {
      return (
        <li
          className="more-button-list-item"
          onClick={() => removeMessagesForCharacter()}
        >
          <Delete />
          <span>Clear Chat</span>
        </li>
      );
    }

    return (
      <>
        <li
          className={`more-button-list-item ${microphoneEnabled ? "enabled" : "disabled"}`}
          onClick={() => toggleMicrophone()}
        >
          {microphoneEnabled ? <Mic /> : <MicOff />}
          <span>Voice Chat</span>
        </li>
        <li
          className="more-button-list-item"
          onClick={() => removeMessagesForCharacter()}
        >
          <Delete />
          <span>Clear Chat</span>
        </li>
        <li
          className={`more-button-list-item ${followupEnabled ? "enabled" : "disabled"}`}
          onClick={() => toggleFollowups()}
        >
          <CornerMaxButton blockIsActive={true} location="chat-menu">
            <Repeat />
            <span>Follow ups</span>
          </CornerMaxButton>
        </li>
        <li
          className="more-button-list-item"
          onClick={() => toggleWorldSim()}
        >
          <CornerMaxButton blockIsActive={true} location="chat-menu">
            <Public />
            <span>WorldSim</span>
          </CornerMaxButton>
        </li>
        <li
          className={`more-button-list-item ${followupEnabled ? "enabled" : "disabled"}`}
          onClick={() => setTemporaryConfig()}
        >
          <CornerMaxButton blockIsActive={true} location="chat-menu">
            <Settings />
            <span>Roles</span>
          </CornerMaxButton>
        </li>
      </>
    );
  };

  return (
    <React.Fragment>
      <div className={`list-container ${isActive ? "active" : ""}`}>
        <button
          className="more-button"
          aria-label="Menu Button"
          onClick={handleClick}
        >
          <div className="menu-icon-wrapper">
            <div className="menu-icon-line"></div>
            <div className="menu-icon-line"></div>
            <div className="menu-icon-line"></div>
          </div>
        </button>
        <ul className="more-button-list">
          {renderMenuItems()}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default ChatMenuButton;