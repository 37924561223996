import React from "react";
import { useNavigate } from "react-router-dom";

const PaymentFailed: React.FC = () => {
  const navigate = useNavigate();
  const handleClick = async () => {
    navigate("/account");
  };

  return (
    <div style={{ textAlign: "center", padding: "20px", color: "red" }}>
      <h1>Payment Failed</h1>
      <p>Unfortunately, your payment could not be processed. Please try again.</p>
      <button onClick={handleClick}></button>
    </div>
  );
};

export default PaymentFailed;