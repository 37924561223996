import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowUpFromBracket,
  faMinus,
  faPlus,
  faSync,
  faTimes,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import ChatMenuButton from "./ChatMenuButton";
import Waveform from "./Waveform";
import TextareaAutosize from "react-textarea-autosize";
import { isAxiosError } from "axios";
import posthog from "posthog-js";
import Lottie from "react-lottie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import scenegptEatingPopcorn from "../../assets/lotties/sceneGPT.jpg";
import {
  clickAd,
  fetchAd,
  fetchAdNew,
  getExampleQuestions,
  getExampleQuestionsGpt,
  handleDislike,
  handleLike,
  sendMessage
} from "../../api/apiClient";
import {
  getWithExpiry,
  getWithoutExpiry,
  incrementStorageItem,
  removeItem,
  setWithExpiry,
  setWithoutExpiry
} from "../../helper/storageUtils";
import { Cast } from "../../types/CastType";
import Arrow from "../miscs/Arrow";
import creditStarAnimation from "../../assets/lotties/credit-star-btn.json";
import ChatToneButton from "../behaviour/ChatToneButton";
import ChatThemeButton from "../behaviour/ChatThemeButton";
import ChatLengthButton from "../behaviour/ChatLengthButton";
import ChatLanguageButton from "../behaviour/ChatLanguageButton";
import log from "loglevel";
import * as Sentry from "@sentry/react";
import { AuthenticatedUser } from "../../helper/authHelper";
import { Microphone } from "./Microphone";
import { UserDetailsType } from "../../types/UserDetailsType";
import UserDetailsManager from "../modals/UserDetailsModal";
import { v4 as uuidv4 } from "uuid";
import gsap from "gsap";
import { useHybridSystem } from "../user/HybridSystem";
import { Session } from "@supabase/supabase-js";
import { AnimatePresence, motion } from "framer-motion";


const WorldsimToast: React.FC = () => (
  <div style={{ fontSize: "0.9em", whiteSpace: "pre-line" }}>
    Worldsim enabled.
    <br />
    <br /><i>Commands:</i>
    <br /><i>*<b>explore</b>* - Describe surroundings</i>
    <br /><i>*<b>meet</b>* - Intro related character</i>
    <br /><i>*<b>learn</b>* - Learn fact about world</i>
    <br /><i>*<b>reflect</b>* - Internal monologue</i>
    <br /><i>*<b>go [location]</b>* - New location</i>
  </div>
);


export interface TranscriptCallResponseType {
  transcript: string;
  sent_audio_file: string;
  audio_file: string;
  message: string;
  remaining_quota: number;
  session_token: string;
}

export interface Message {
  id?: string;
  sender: "user" | "character";
  content: string;
  audio?: string;
  transcript?: string;
  transcriptVisible?: boolean;
  isAd?: boolean;
  adLink?: string;
  adId?: string;
  followUp?: string[];
  adTextFull?: string;
  adText?: {
    beforeHighlight: string;
    highlight: string;
    afterHighlight: string;
  };
  adLogo?: string;
  message_id?: string;
  liked?: boolean;
  disliked?: boolean;
  timestamp?: string;
  profileImage?: string;
  showMenu?: boolean;
  error?: boolean;
}

const getProfileImageUrl = (character: { profile_path?: string, name: string }) => {
  if (!character.profile_path) {
    return `${process.env.PUBLIC_URL}/Placeholder-Portrait.jpg`;
  }

  if (character.profile_path.includes("/static/media/")) {
    return character.profile_path;
  }

  if (character.profile_path.startsWith("https")) {
    return character.profile_path;
  }

  return `https://image.tmdb.org/t/p/w500${character.profile_path}`;
};

interface InnerChatProps {
  chat_type: string;
  changeCharacterInfiniteScroll: () => void;
  setPreviousCharacter: () => void;
  setNextCharacter: () => void;
  signUpModalVisible: boolean;
  subscribeModalVisible: boolean;
  ratingModalVisible: boolean;
  movie: { title: string };
  setLimitModalVisible: (visible: boolean) => void;
  setAdConsentModal: (visible: boolean) => void;
  setSurveyModal: (visible: boolean) => void;
  setSignUpModalVisible: (visible: boolean) => void;
  setRatingModalVisible: (visible: boolean) => void;
  setSubscribeModal: (visible: boolean) => void;
  selectedCharacterObject: Cast;
  user?: AuthenticatedUser;
  session?: Session;

  setSelectedCharacterObject(character: Cast | undefined): void;
}

interface ShareTooltipProps {
  isVisible: boolean;
}

const ShareTooltip: React.FC<ShareTooltipProps> = ({ isVisible }) => (
  <AnimatePresence>
    {isVisible && (
      <motion.div
        initial={{ opacity: 0, y: 5 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -5 }}
        style={{
          marginBottom: "5px",
          background: "#FF8C00",
          color: "#000",
          padding: "2px 6px",
          borderRadius: "4px",
          fontSize: "0.55rem",
          fontWeight: 500,
          whiteSpace: "nowrap",
          zIndex: 9999
        }}
      >
        Share for +5
      </motion.div>
    )}
  </AnimatePresence>
);

const InnerChat: React.FC<InnerChatProps> = (props: InnerChatProps) => {
  const [messages, setMessages] = useState<{ [key: string]: Message[] }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isMicrophoneActive, setIsMicrophoneActive] = useState(true);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [inputMessage, setInputMessage] = useState("");
  const [exampleQuestions, setExampleQuestions] = useState<string[]>([]);
  const [remainingMessages, setRemainingMessages] = useState(props?.user?.remainingQuota || 0);
  const [characterTone, setCharacterTone] = useState("");
  const [characterTheme, setCharacterTheme] = useState("");
  const [chatLength, setChatLength] = useState("");
  const [chatLanguage, setChatLanguage] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [followupBool, setFollowupBool] = useState(true);
  const [worldsimBool, setWorldsimBool] = useState(true);
  const navigate = useNavigate();
  const [promptedMessages, setPromptedMessages] = useState<Set<string>>(new Set());
  const [isCooldown, setIsCooldown] = useState(false);
  const [isShaking, setIsShaking] = useState(false);
  const [temporaryBlob, setTemporaryBlob] = useState("");
  const [temporaryUserDetails, setTemporaryUserDetails] = useState<UserDetailsType>({
    name: "",
    age: 0,
    profession: "",
    hobby: "",
    gender: "",
    location: ""
  });
  const [temporaryModalBool, setTemporaryModalBool] = useState(false);
  const [adServeCount, setAdServeCount] = useState(0);
  const renderCountRef = useRef(0);
  const [isOnLimitedModel, setIsOnLimitedModel] = useState(false);

  const messagesRef = useRef(messages);
  const [isMounted, setIsMounted] = useState(false);
  const chatInputRef = useRef<HTMLDivElement>(null);
  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);

  const [placeholderText, setPlaceholderText] = useState("");
  const normalQuestions = [
    "What were you thinking when...",
    "Why'd you really...",
    "Always wanted to ask you...",
    "Did you actually...",
    "What happened after...",
    "Who knew about...",
    "How'd you feel when...",
    "Was it worth it when...",
    "Tell me the truth about...",
    "What if you had...",
    "What happened after...?",
    "Did you ever regret...?",
    "Why the sudden change...?"
  ];

  const actionLines = [
    "*Nods*",
    "*Smirks*",
    "*Grins*",
    "*Waves hand dismissively*",
    "*Points*",
    "*Rolls eyes*",
    "*Clasps hands together*",
    "*Looks away*",
    "*Eyes narrow with suspicion*",
    "*A long pause*",
    "*Hint of Pain crosses my face*",
    "*Nods slowly, considering*",
    "*Eyes narrow with intent*",
    "*Smirks, amused*",
    "*Takes a slow step forward*",
    "*Glances sideways, suspicious*",
    "*Hands clench into fists*",
    "*Raises an eyebrow, intrigued*",
    "*Shrugs, indifferent*",
    "*Turns away, dismissive*",
    "*Leans in, lowering voice*",
    "*Eyes dart around the room*",
    "*Crosses arms, waiting*",
    "*Looks down, hiding a smile*",
    "*Jaw tightens, holding back*",
    "*Tilts head, curious*",
    "*Exhales sharply, frustrated*",
    "*Pauses, listening intently*",
    "*Rolls eyes, unimpressed*",
    "*Touches chin, thoughtful*",
    "*Grins, knowing something*",
    "*Sips drink*",
    "*Stares blankly*",
    "*Yawns*",
    "*Huffs*",
    "*Tilts head*",
    "*Crosses arms*",
    "*Purses lips*",
    "*Scratches head*",
    "*Rubs temples*",
    "*Bites lip*",
    "*Leans back*",
    "*Taps foot*",
    "*Coughs*",
    "*Squirms*",
    "*Bows slightly*",
    "*Points*"
  ];


  let winStreakSystem: any;

  try {
    winStreakSystem = useHybridSystem();
  } catch (e) {
    Sentry.captureException(e);
  }

  const [showShareTooltip, setShowShareTooltip] = useState(false);

  useEffect(() => {
    const remainingQuota = getWithoutExpiry("remaining_quota") ?? undefined;
    if (remainingQuota !== undefined) {
      setRemainingMessages(parseInt(remainingQuota));
    }

    if (
      props.chat_type === "sceneGPT" || props.selectedCharacterObject.character === "Scenester"
    ) {
      fetchExampleQuestionsGpt();
    } else {
      fetchExampleQuestions();
    }

    const timer = setTimeout(() => {
      if (!messagesRef.current[props.selectedCharacterObject.character]) {
        introduceCharacter(props.selectedCharacterObject.character);
      }
    }, 300);

    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    getMessagesFromLocalStorage();
  }, [props.selectedCharacterObject.character]);

  useEffect(() => {
    const remainingQuota = getWithoutExpiry("remaining_quota") ?? undefined;
    if (remainingQuota !== undefined) {
      setRemainingMessages(parseInt(remainingQuota));
    }
  }, [props.ratingModalVisible]);

  useEffect(() => {
    const remainingQuota = getWithoutExpiry("remaining_quota") ?? undefined;
    if (remainingQuota !== undefined) {
      setRemainingMessages(parseInt(remainingQuota));
    }
    storeMessagesInLocalStorage(messages);

    scrollToBottom();
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    if (props.user && props.user.isPremium != "premium") {
      const currentMessages = messages[props.selectedCharacterObject.character] || [];

      if (currentMessages.length === 3) {
        setShowShareTooltip(true);
        setTimeout(() => setShowShareTooltip(false), 30000);
      }
    }
  }, [messages, props.selectedCharacterObject.character]);


  const serveAd = async () => {
    if (isCooldown) {
      toast.info("Please wait a bit before clicking", { theme: "dark" });
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 500);
      return;
    }
    setIsPlaying(true);

    try {
      const adData = await fetchAd(props.session);

      const adMessage: Message = {
        sender: "character",
        content: adData.text,
        isAd: true,
        adTextFull: adData.text,
        adLink: adData.linkUrl,
        adId: adData.id
      };

      setTimeout(() => setIsPlaying(false), 3000); // Stop the animation after 3 seconds
      setMessages((prevMessages) => ({
        ...prevMessages,
        [props.selectedCharacterObject.character]: [
          ...(prevMessages[props.selectedCharacterObject.character] || []),
          adMessage
        ]
      }));

      const remainingQuota = getWithoutExpiry("remaining_quota") ?? undefined;
      if (remainingQuota !== undefined) {
        setRemainingMessages(parseInt(remainingQuota));
      }
      setIsCooldown(true);
      setTimeout(() => {
        setIsCooldown(false);
      }, 2500);
    } catch (e) {
      Sentry.captureException(e);
      log.error(e);
      toast.error("Ad generation failed.", { theme: "dark" });
      setIsPlaying(false);
    }


  };

  const serveAdNew = async () => {
    if (isCooldown) {
      toast.info("Please wait a bit before clicking", { theme: "dark" });
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 500);
      return;
    }
    setIsPlaying(true);

    try {
      const adData = await fetchAdNew(props.session);

      // Split the text into parts: before highlight, highlight, and after highlight
      const highlightIndex = adData.text.indexOf(adData.highlight);
      let beforeHighlight = "";
      let afterHighlight = "";

      if (highlightIndex !== -1) {
        beforeHighlight = adData.text.slice(0, highlightIndex);
        afterHighlight = adData.text.slice(highlightIndex + adData.highlight.length);
      }

      const adMessage: Message = {
        sender: "character",
        content: adData.text,
        isAd: true,
        adText: {
          beforeHighlight,
          highlight: adData.highlight,
          afterHighlight
        },
        adLink: adData.link,
        adId: uuidv4(),
        adLogo: adData.logo
      };

      setTimeout(() => setIsPlaying(false), 3000); // Stop the animation after 3 seconds
      setMessages((prevMessages) => ({
        ...prevMessages,
        [props.selectedCharacterObject.character]: [
          ...(prevMessages[props.selectedCharacterObject.character] || []),
          adMessage
        ]
      }));

      if (adData.remainingQuota !== undefined) {
        setRemainingMessages(adData.remainingQuota);
      }
      setIsCooldown(true);
      setTimeout(() => {
        setIsCooldown(false);
      }, 2500);

      setAdServeCount((prevCount) => {
        const newCount = prevCount + 1;

        if (newCount >= 20) {
          const adMessages = messages[props.selectedCharacterObject.character].filter((msg: any) => msg.isAd);
          const lastAd = adMessages[adMessages.length - 1];
          trackClick(lastAd.adLink || "", lastAd.adId || "");
          if (lastAd.adLink && lastAd.adLink !== "" && window != null) {
            window.open(lastAd.adLink, "_blank");
          }
          return 0;
        }

        return newCount;
      });

    } catch (e) {
      log.error(e);
      toast.error("Ad generation failed.", { theme: "dark" });
      Sentry.captureException(e);
      setIsPlaying(false);
    }
  };

  const getMessagesFromLocalStorage = () => {
    const messagesJson = getWithoutExpiry("messages");
    if (messagesJson) {
      setMessages(JSON.parse(messagesJson));
    } else {
      setMessages({});
    }
  };


  const storeMessagesInLocalStorage = async (messages: {
    [p: string]: Message[];
  }) => {
    if (Object.keys(messages).length !== 0) {
      const messagesJson = JSON.stringify(messages);
      setWithoutExpiry("messages", messagesJson);
    }
  };

  const addIntroducedCharacter = (characterName: string) => {
    const introducedCharacters = getWithoutExpiry("introducedCharacters") || [];
    if (!introducedCharacters.includes(characterName)) {
      introducedCharacters.push(characterName);
      setWithoutExpiry("introducedCharacters", introducedCharacters);
    }
  };

  const removeIntroducedCharacter = (characterName: string) => {
    const introducedCharacters = getWithoutExpiry("introducedCharacters") || [];
    const updatedCharacters = (introducedCharacters as string[]).filter((char: string) => {
      return char !== characterName;
    });
    setWithoutExpiry("introducedCharacters", updatedCharacters);
  };

  const isCharacterIntroduced = (characterName: string): boolean => {
    const introducedCharacters = getWithoutExpiry("introducedCharacters") || [];
    return introducedCharacters.includes(characterName);
  };

  const removeMessagesForCharacter = async () => {
    if (localStorage) {
      const messagesJson = getWithoutExpiry("messages");
      if (messagesJson) {
        const messages = JSON.parse(messagesJson);
        // Remove the specified character's messages
        delete messages[props.selectedCharacterObject.character];
        // Save the updated messages back to localStorage
        setWithoutExpiry("messages", JSON.stringify(messages));
        removeIntroducedCharacter(props.selectedCharacterObject.character);
        getMessagesFromLocalStorage();
        removeItem(`X-Session-Token-${props.selectedCharacterObject.character}`);

        introduceCharacter(props.selectedCharacterObject.character);
      }
    }

    toast.info("Chat restarted");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    if (!inputMessage || !props.selectedCharacterObject.character) return;

    // Add this check to prevent sending a message when inputMessage is empty
    if (inputMessage.trim() === "") return;

    if (
      remainingMessages <= 0 &&
      getWithoutExpiry("remaining_quota") <= 0 && props.user && props.user.isPremium != "premium"
    ) {

      props.setAdConsentModal(true);
      // props.setSubscribeModal(true);


      // toast.error("⭐ You ran out of credits! 😞", { autoClose: 1500, theme: "dark" });
      return;
    }

    await sendActualMessage(null);
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  const toggleTranscriptVisibility = (index: number) => {
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages[props.selectedCharacterObject.character]];
      newMessages[index].transcriptVisible =
        !newMessages[index].transcriptVisible;
      return {
        ...prevMessages,
        [props.selectedCharacterObject.character]: newMessages
      };
    });
  };

  const insertCharacterMessageTranscript = (message: Message) => {
    posthog.capture("transcript_inserted_character", {
      character: props.selectedCharacterObject.character
    });
    if (!props.selectedCharacterObject.character || !message.content) return;
    setMessages((prevMessages) => ({
      ...prevMessages,
      [props.selectedCharacterObject.character]: [
        ...(prevMessages[props.selectedCharacterObject.character] || []),
        {
          ...message,
          transcriptVisible: false,
          timestamp: new Date().toISOString(),
          profileImage: getProfileImageUrl(props.selectedCharacterObject)
        }
      ]
    }));
  };

  const fetchExampleQuestions = async () => {
    try {
      const storedQuestions = getWithExpiry("example_questions");
      if (storedQuestions) {
        try {
          const shuffledQuestions = shuffleArray(storedQuestions);
          setExampleQuestions(shuffledQuestions.slice(0, 2));
          log.info("type of response:" + typeof storedQuestions + " value: " + storedQuestions);
        } catch (error) {
          Sentry.captureException(error);
          log.error(error);
        }
      } else {
        const questions = await getExampleQuestions();
        setWithExpiry("example_questions", questions, 3600000); // 1 hour TTL
        const shuffledQuestions = shuffleArray(questions);
        setExampleQuestions(shuffledQuestions.slice(0, 2));
      }
    } catch (error) {
      Sentry.captureException(error);
      log.error(error);
    }
  };


  const shuffleArray = (array: string[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const introduceCharacter = async (selectedCharacter: string) => {
    try {
      posthog.capture("character_introduced", {
        character: selectedCharacter
      });
      const introducedCharactersArray: string[] = getWithoutExpiry("introducedCharacters") || [];

      if (introducedCharactersArray.includes(selectedCharacter)) {
        return;
      }
      setIsLoading(true);
      const response = await sendMessage(props.session,
        {
          message: "",
          character: selectedCharacter.replace("(voice)", ""),
          series: props.chat_type == "Scenester" ? "" : props.movie.title,
          userDetails: temporaryUserDetails
        },
        selectedCharacter,
        true
      );

      setMessages((prevMessages) => ({
        ...prevMessages,
        [selectedCharacter]: [
          ...(prevMessages[selectedCharacter] || []),
          {
            sender: "character",
            content: response.message,
            message_id: response.conversation_id,
            timestamp: new Date().toISOString(),
            profileImage: getProfileImageUrl(props.selectedCharacterObject)
          }
        ]
      }));
      introducedCharactersArray.push(selectedCharacter);
      localStorage.setItem(
        "introducedCharacters",
        JSON.stringify(introducedCharactersArray)
      );
    } catch (e) {
      log.error(e);
      Sentry.captureException(e);
      toast.error("Character intro failed. Refresh.", { theme: "dark" });
      setMessages((prevMessages) => ({
        ...prevMessages,
        [selectedCharacter]: [
          ...(prevMessages[selectedCharacter] || []),
          {
            sender: "character",
            content: "",
            timestamp: new Date().toISOString(),
            profileImage: getProfileImageUrl(props.selectedCharacterObject),
            error: true
          }
        ]
      }));
    } finally {
      setIsLoading(false);
    }
  };

// Updated fetchExampleQuestionsGpt function
  const fetchExampleQuestionsGpt = async () => {
    try {
      // Check if questions are already in sessionStorage
      const storedQuestions = getWithExpiry("example_questions_gpt");
      if (storedQuestions) {
        try {
          const shuffledQuestions = shuffleArray(storedQuestions);
          setExampleQuestions(shuffledQuestions.slice(0, 2));
          log.info("type of response:" + typeof storedQuestions + " value: " + storedQuestions);
        } catch (error) {
          Sentry.captureException(error);
          log.error(error);
        }
      } else {
        const questions = await getExampleQuestionsGpt();
        setWithExpiry("example_questions_gpt", questions, 3600000); // 1 hour TTL
        const shuffledQuestions = shuffleArray(questions);
        setExampleQuestions(shuffledQuestions.slice(0, 2));
      }
    } catch (error) {
      Sentry.captureException(error);
      log.error(error);
    }
  };

  const sendActualMessage = async (message: string | null, messageId = "") => {
    const tempId = Date.now().toString();
    try {
      const inputMessageToSend = inputMessage ? inputMessage : message;
      if (!props.selectedCharacterObject.character || !inputMessageToSend) return;

      setIsLoading(true);

      // If messageId is provided, we're resending a failed message
      if (messageId) {
        setMessages((prevMessages) => ({
          ...prevMessages,
          [props.selectedCharacterObject.character]: prevMessages[props.selectedCharacterObject.character].map(msg =>
            msg.id === messageId ? { ...msg, error: false } : msg
          )
        }));
      } else {
        // Add new user message only if it's not a resend
        setMessages((prevMessages) => ({
          ...prevMessages,
          [props.selectedCharacterObject.character]: [
            ...(prevMessages[props.selectedCharacterObject.character] || []),
            {
              id: tempId,
              sender: "user",
              content: inputMessageToSend,
              timestamp: new Date().toISOString(),
              profileImage: getProfileImageUrl(props.selectedCharacterObject)
            }
          ]
        }));
        setInputMessage("");
      }

      posthog.capture("message_sent", {
        character: props.selectedCharacterObject.character,
        tone: characterTone,
        theme: characterTheme,
        length: chatLength,
        language: chatLanguage
      });

      const series = props.chat_type == "Scenester" ? "" : (getWithExpiry("last_movie") || "Trending Characters");

      const response = await sendMessage(props.session,
        {
          message: inputMessageToSend,
          character: props.selectedCharacterObject.character.replace("(voice)", ""),
          series: props.chat_type == "Scenester" ? "" : props.movie.title,
          config: {
            tone: characterTone,
            theme: characterTheme,
            length: chatLength,
            language: chatLanguage,
            followup: followupBool,
            worldsim: worldsimBool
          },
          userDetails: temporaryUserDetails
        },
        props.selectedCharacterObject.character,
        false
      );

      setIsLoading(false);

      // Add character response
      setMessages((prevMessages) => ({
        ...prevMessages,
        [props.selectedCharacterObject.character || ""]: [
          ...(prevMessages[props.selectedCharacterObject.character] || []),
          {
            sender: "character",
            content: response.message,
            message_id: response.conversation_id,
            timestamp: new Date().toISOString(),
            profileImage: getProfileImageUrl(props.selectedCharacterObject)
          }
        ]
      }));

      setPromptedMessages(response.follow_up_questions);
      incrementStorageItem("messages_count");
      setWithoutExpiry("remaining_quota", response.remainingQuota);
      winStreakSystem?.openModalAndUpdate();
      // Handle rating and survey modals
      const messageCount = getWithoutExpiry("remaining_quota") ?? 0;
      const hasRated = getWithoutExpiry("hasRated");
      if (!hasRated && messageCount <= 1) {
        props.setRatingModalVisible(true);
      }
      if (getWithoutExpiry("messages_count") == 5 || getWithoutExpiry("messages_count") == 15) {
        props.setSurveyModal(true);
      }
    } catch (error: unknown) {
      setIsLoading(false);
      Sentry.captureException(error);

      setMessages((prevMessages) => ({
        ...prevMessages,
        [props.selectedCharacterObject.character]: prevMessages[props.selectedCharacterObject.character].map(msg =>
          msg.sender === "user" && msg.id === tempId
            ? { ...msg, error: true }
            : msg
        )
      }));

      if (isAxiosError(error) && error.response?.data?.detail === "QUOTA_EXCEEDED") {
        log.info("quota exceeded");
        if (remainingMessages <= 0 && props.user && props.user.isPremium !== "premium") {
          props.setAdConsentModal(true);
          // props.setSubscribeModal(true);
        }
      } else {
        log.error("API request failed:", error);
      }
      setIsLoading(false);
    }
  };

  const insertUserMessageTranscript = async (
    payload: TranscriptCallResponseType
  ) => {
    posthog.capture("transcript_inserted_user", {
      character: props.selectedCharacterObject.character
    });

    if (!props.selectedCharacterObject.character || (!payload.transcript && !payload.message))
      return;

    setMessages((prevMessages) => {
      const currentMessages = prevMessages[props.selectedCharacterObject.character] || [];
      const lastMessageIndex = currentMessages.length - 1;

      // Check if the last message meets the specified conditions
      if (
        lastMessageIndex >= 0 &&
        currentMessages[lastMessageIndex].sender === "user" &&
        currentMessages[lastMessageIndex].transcript === "" &&
        currentMessages[lastMessageIndex].audio
      ) {
        // Update the transcript of the last message
        const updatedLastMessage = {
          ...currentMessages[lastMessageIndex],
          transcript: payload.transcript

        };

        // Replace the last message with the updated one
        return {
          ...prevMessages,
          [props.selectedCharacterObject.character]: [
            ...currentMessages.slice(0, lastMessageIndex),
            updatedLastMessage

          ]
        };
      } else {
        // Append the new message as usual
        return {
          ...prevMessages,
          [props.selectedCharacterObject.character]: [
            ...currentMessages,
            {
              sender: "user",
              content: payload.transcript,
              transcript: payload.transcript,
              audio: payload.sent_audio_file,
              timestamp: new Date().toISOString(),
              profileImage: getProfileImageUrl(props.selectedCharacterObject)
            }
          ]
        };
      }
    });
  };

  const toggleMicrophone = () => {
    setIsMicrophoneActive((prevState) => !prevState);
    toast.info(isMicrophoneActive ? "Microphone disabled" : "Microphone enabled");
  };

  const toggleFollowups = () => {
    setFollowupBool((prevState) => !prevState);
    toast.info(followupBool ? "Followup questions disabled" : "Followup questions enabled");
  };

  const toggleWorldSim = () => {
    setWorldsimBool((prevState) => !prevState);
    toast.info(
      worldsimBool
        ? "Worldsim disabled"
        : <WorldsimToast />,
      {
        style: {
          maxWidth: "300px" // Adjust as needed
        }
      }
    );
  };


  const handleBack = () => {
    if (props.chat_type == "carousel" || props.chat_type == "sceneGPT") {
      navigate("/search");
    }
    props.setSelectedCharacterObject(undefined);
  };

  const handleDemoMessageSubmit = async (
    e: React.FormEvent,
    question: string
  ) => {
    e.preventDefault();
    if (remainingMessages <= 0 && !getWithExpiry("logged_in")) {
      props.setSignUpModalVisible(true);
      return;
    }
    if (remainingMessages <= 0 && props.user && props.user.isPremium != "premium") {
      // toast.error("⭐ You ran out of credits! 😞", { autoClose: 1500, theme: "dark" });


      props.setAdConsentModal(true);
      // props.setSubscribeModal(true);
      // props.setLimitModalVisible(true);
      return;
    }

    if (!props.selectedCharacterObject.character) return;

    await sendActualMessage(question);
  };

  const trackClick = (url: string, ad_id: string) => {
    try {
      clickAd(props.session, ad_id).then(() => {
        const remainingQuota = getWithoutExpiry("remaining_quota") ?? undefined;
        if (remainingQuota !== undefined) {
          setRemainingMessages(parseInt(remainingQuota));
        }
      });
    } catch (e) {

      Sentry.captureException(e);
      toast.error("Ad Click failed.", { theme: "dark" });
    }
  };

  const handleCharacterLike = async (index: number, character: string) => {
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages[character]];
      if (!newMessages[index].liked) {
        newMessages[index].liked = true;
        newMessages[index].disliked = false;

        if (newMessages[index].message_id) {
          handleLike(props.session, newMessages[index]);
        }
      }
      return {
        ...prevMessages,
        [character]: newMessages
      };
    });
  };

  const handleCharacterDislike = async (index: number, character: string) => {
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages[character]];
      if (!newMessages[index].disliked) {
        newMessages[index].disliked = true;
        newMessages[index].liked = false;

        if (newMessages[index].message_id) {
          handleDislike(props.session, newMessages[index]);
        }
      }
      return {
        ...prevMessages,
        [character]: newMessages
      };
    });
  };

  // useEffect(() => {
  //   setIsMounted(true);
  //
  //   const handleVisualViewportResize = () => {
  //     if (chatInputRef.current) {
  //       chatInputRef.current.style.transform = "translateZ(0)";
  //       setTimeout(() => {
  //         if (chatInputRef.current) {
  //           chatInputRef.current.style.transform = "";
  //         }
  //       }, 0);
  //     }
  //   };
  //
  //   window.visualViewport?.addEventListener("resize", handleVisualViewportResize);
  //
  //   return () => {
  //     window.visualViewport?.removeEventListener("resize", handleVisualViewportResize);
  //   };
  // }, []);

  const setTemporaryConfig = () => {
    setTemporaryModalBool(prev => !prev);
  };


  const handleClickOutside = (event: MouseEvent) => {
    const actionsContainer = document.querySelector(".input-actions-container");
    const toneMoreButton = document.querySelector(".tone-more-button");
    const languageMoreButton = document.querySelector(".language-more-button");
    const blurOverlay = document.getElementById("blur-overlay");

    if (
      actionsContainer &&
      toneMoreButton &&
      languageMoreButton &&
      blurOverlay &&
      !actionsContainer.contains(event.target as Node) &&
      !toneMoreButton.contains(event.target as Node) &&
      !languageMoreButton.contains(event.target as Node)
    ) {
      actionsContainer.classList.remove("active");
      blurOverlay.classList.remove("active");
      setActionsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const [isActionsVisible, setActionsVisible] = useState(false);

  const toggleInputActions = () => {
    setActionsVisible(!isActionsVisible);
  };


  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const allTexts = [...normalQuestions, ...actionLines];
    let currentIndex = 0;

    const typePlaceholder = () => {
      const text = allTexts[currentIndex];
      let charIndex = 0;

      const typeChar = () => {
        if (charIndex < text.length) {
          setPlaceholderText(text.slice(0, charIndex + 1));
          charIndex++;
          setTimeout(typeChar, 50);
        } else {
          currentIndex = (currentIndex + 1) % allTexts.length;
          setTimeout(typePlaceholder, 2000);
        }
      };

      typeChar();
    };

    typePlaceholder();

    gsap.to(".chat-input", {
      backgroundPosition: "-200% 0",
      duration: 2,
      repeat: -1,
      ease: "none"
    });

    return () => {
      gsap.killTweensOf(".chat-input");
    };
  }, []);

  const deleteAdMessage = (adId: string) => {
    setMessages((prevMessages) => {
      const updatedMessages = { ...prevMessages };
      updatedMessages[props.selectedCharacterObject.character] = prevMessages[
        props.selectedCharacterObject.character
        ].filter((message) => message.adId !== adId);
      return updatedMessages;
    });
  };

  const toggleMenu = (character: string, index: number) => {
    log.info(character, index);
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages[character]];
      updatedMessages[index] = {
        ...updatedMessages[index],
        showMenu: !updatedMessages[index].showMenu
      };

      log.info(updatedMessages);
      return {
        ...prevMessages,
        [character]: updatedMessages
      };
    });
  };


  const handleExport = () => {
    const exportData = {
      character: props.selectedCharacterObject.character,
      userName: props.user?.name || "Anonymous",
      messages: messages[props.selectedCharacterObject.character] || [],
      profileImage: props.selectedCharacterObject.profile_path,
      movie: props.movie
    };

    navigate("/export", { state: { exportData } });
  };

  const processContent = (content: string): string => {
    // Split the content into lines
    const lines: string[] = content.split("\n");
    let processedContent = "";
    let inList = false;

    for (const line of lines) {
      // Regex to match lines that start with number.dot.asterisk.asterisk
      const match: RegExpMatchArray | null = line.match(/^\s*(\d+\.)\s*\*\*(.*?)\*\*(.*)$/);

      if (match) {
        // If we're not already inside a list, start one
        if (!inList) {
          inList = true;
          processedContent += "<ol>";
        }

        const number = match[1];
        let title = match[2];
        const description = match[3];

        // Process title: italicize text in double quotes
        title = title.replace(/"([^"]*)"/g, "<i>\"$1\"</i>");

        // Combine number, title, and description into a list item
        processedContent += `<li><strong>${title}</strong>${description}</li>`;
      } else {
        // If we were inside a list and the pattern doesn't match anymore, close the list
        if (inList) {
          inList = false;
          processedContent += "</ol>";
        }

        // Process the line
        const processedLine = line
          // Italicize text in double quotes
          .replace(/"([^"]*)"/g, "<i>\"$1\"</i>")
          // Convert Markdown-style links to HTML anchor tags
          .replace(
            /\[([^\]]+)\]\((https?:\/\/[^\s)]+)\)/g,
            "<a href=\"$2\" target=\"_blank\" rel=\"noopener noreferrer\">$1</a>"
          )
          // Handle bold text between double asterisks
          .replace(/\*\*([^*]+)\*\*/g, "<strong><i>$1</i></strong>")
          // Handle text between single asterisks
          .replace(/\*([^*]+)\*/g, (match: string, p1: string): string => {
            const wordCount = p1.trim().split(/\s+/).length;
            if (wordCount > 2) {
              // More than 2 words, wrap in <strong> and place on its own line
              return `\n<strong><i>${p1}</i></strong>\n`;
            } else {
              // 1 or 2 words, wrap in <strong style="display:inline"> and keep inline
              return `<strong style="display:inline"><i>${p1}</i></strong>`;
            }
          });

        // Split processedLine by newlines to handle lines with standalone <strong> tags
        const lineParts = processedLine.split("\n");

        for (const part of lineParts) {
          const trimmedPart = part.trim();
          if (trimmedPart) {
            if (/^<strong>.*<\/strong>$/.test(trimmedPart)) {
              // If the part is only a <strong> tag, output it without <p> tags
              processedContent += `${trimmedPart}`;
            } else {
              // Determine padding based on the length of the trimmed part
              const paddingStyle = trimmedPart.length > 10 ? "10px" : "0px";

              // Wrap the part in <p> tags with inline padding style
              processedContent += `<p style="margin: ${paddingStyle} 0px !important;">${trimmedPart}</p>`;
            }
          }
        }
      }
    }

    // Close the list if it wasn't closed already
    if (inList) {
      processedContent += "</ol>";
    }

    return processedContent;
  };


  return (
    <>
      {temporaryModalBool && <UserDetailsManager initialDetails={temporaryUserDetails}
                                                 setInitialDetails={setTemporaryUserDetails} />}

      <div className="chat-container-in-chat">
        <div className="name-div">
          <Arrow onClick={handleBack} />
          {props.chat_type === "sceneGPT" ? (
            <img
              className="character-avatar-small"
              src={scenegptEatingPopcorn}
              alt="SceneGPT eating popcorn"
            />
          ) : (
            props.selectedCharacterObject && (
              <img
                className="character-avatar-small"
                src={getProfileImageUrl(props.selectedCharacterObject)}
                alt={`${props.selectedCharacterObject.name} Avatar`}
              />
            )
          )}
          <div className="character-info">
            <div className="selected-character">
              {props.selectedCharacterObject.character.replace("(voice)", "")}
            </div>
            {isLoading && (
              <div className="typing-and-loading">
                <span className="typing-indicator">
                  typing{" "}
                  <div className="loading">
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                  </div>
                </span>
              </div>
            )}
            {isRecording && (
              <div className="typing-and-loading">
                <span className="typing-indicator">
                  recording{" "}
                  <div className="loading">
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                  </div>
                </span>
              </div>
            )}
          </div>
          <div className="export-button" style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            alignContent: "space-around",
            justifyContent: "space-between",
            flexWrap: "nowrap"
          }}>
            <ShareTooltip isVisible={showShareTooltip} />
            <FontAwesomeIcon icon={faArrowUpFromBracket} onClick={handleExport} />
          </div>

          <div className="tooltip" style={{ visibility: "visible" }}>
            <ChatMenuButton
              toggleMicrophone={toggleMicrophone}
              removeMessagesForCharacter={removeMessagesForCharacter}
              toggleFollowups={toggleFollowups}
              microphoneEnabled={isMicrophoneActive}
              followupEnabled={followupBool}
              setTemporaryConfig={setTemporaryConfig}
              currentCharacter={props.selectedCharacterObject.character}
              toggleWorldSim={toggleWorldSim}
            />
          </div>

        </div>
        <div ref={messagesContainerRef} className="chat-messages-container" style={{
          justifyContent: props.selectedCharacterObject.character ? "space-between" : "flex-start",
          display: props.selectedCharacterObject.character && messages[props.selectedCharacterObject.character] && messages[props.selectedCharacterObject.character].length > 1 ? "block" : "flex"
        }}>
          {props.selectedCharacterObject.character && messages[props.selectedCharacterObject.character]
            ? messages[props.selectedCharacterObject.character].map((message, index) => (
              <>
                <div
                  className={`chat-message ${
                    message.sender === "user"
                      ? "user-message slide-in"
                      : "character-message slide-in"
                  } ${message.error ? "error-message" : ""}`}
                >
                  {message.error && (
                    <button
                      className="resend-button"
                      onClick={() => sendActualMessage(message.content, message.id)}
                    >
                      <FontAwesomeIcon icon={faSync} className="refresh-icon" />
                    </button>
                  )}
                  {message.isAd ? (
                    <>
                      <a href={message.adLink} className="ad-container" onClick={(e) => {
                        e.preventDefault();
                        trackClick(message.adLink || "", message.adId || "");
                        if (message.adLink && message.adLink != "" && window != null) {
                          window.open(message.adLink, "_blank");
                        }
                      }}>
                        <div className="da-content">
                          <span className="da-label">Sponsored</span>
                          <h3
                            className="da-title">{message.adText?.highlight || message.adTextFull?.split(" ").slice(0, 3).join(" ")}</h3>
                          <p className="da-tagline">
                            {message.adText ?
                              message.adText.beforeHighlight + message.adText.afterHighlight :
                              message.adTextFull?.split(" ").slice(3).join(" ") || message.content
                            }
                          </p>
                        </div>
                        <div className="da-footer">
                          <div className="da-logo">
                            <img src={message.adLogo} alt="Ad Logo" />
                          </div>
                          <span className="da-learn-more">Learn More</span>
                        </div>
                      </a>
                    </>
                  ) : message.audio ? (
                    <>
                      <div className={"max-width"}>
                        <div
                          style={{
                            width: 350,
                            borderRadius: "10px",
                            padding: "0rem 1rem",
                            margin: "0 auto"
                          }}
                        >
                          <Waveform audioUrl={message.audio} blobUrl={temporaryBlob} />
                        </div>
                        <button
                          className="transcript-button"
                          onClick={() => toggleTranscriptVisibility(index)}
                        >
                          <span className="icon">☰</span>{" "}
                          {message.transcriptVisible
                            ? "Hide Transcript"
                            : "View Transcript"}
                        </button>
                        {message.transcriptVisible && <p className={"transcript"}>{message.transcript}</p>}
                      </div>
                    </>
                  ) : (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: processContent(message.content)
                      }}
                    />
                  )}
                  {/* Add the like and dislike buttons only for character messages */}
                  {message.sender === "character" && (
                    <div className="reaction-buttons character-reaction">
                      <button
                        className={`like-button ${message.liked ? "liked" : ""}`}
                        onClick={() => handleCharacterLike(index, props.selectedCharacterObject.character)}
                      >
                        👍
                      </button>
                      <button
                        className={`dislike-button ${message.disliked ? "disliked" : ""}`}
                        onClick={() => handleCharacterDislike(index, props.selectedCharacterObject.character)}
                      >
                        👎
                      </button>
                    </div>
                  )}
                  {message.isAd && (
                    <>
                      <div className="message-actions">
                        <button
                          className="arrow-down-btn"
                          onClick={() => toggleMenu(props.selectedCharacterObject.character, index)}
                        >
                          ▼
                        </button>
                        {message.showMenu && (
                          <div className="popup-window">
                              <span
                                className="ad-delete-icon"
                                onClick={() => deleteAdMessage(message.adId || "")}
                              >
                                <FontAwesomeIcon icon={faTrash} className="trash-icon" />
                                <span className="ad-delete-text">Delete</span>
                              </span>

                          </div>
                        )}
                      </div>
                    </>
                  )
                  }
                </div>
                {props.selectedCharacterObject.character != "Scenester" && message.sender === "character" && index === messages[props.selectedCharacterObject.character].length - 1 && messages[props.selectedCharacterObject.character]?.length >= 2 && (
                  <div className="follow-up-prompts-container">
                    {Array.from(promptedMessages).map((prompt, promptIndex) => (
                      <div key={`${index}-${promptIndex}`} className="follow-up-prompt">
                        <span className="prompt-plus">+</span>
                        <button onClick={(e) => {
                          if (props.selectedCharacterObject.character != "Scenester") {
                            log.info("not sceneGPT");
                            handleDemoMessageSubmit(e, prompt);
                          } else {
                            setInputMessage(prompt);
                          }
                        }} className="prompt-button">
                          {prompt}
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </>
            ))
            : <>
              <div><p></p></div>
            </>}

          {messages[props.selectedCharacterObject.character]?.length == 1 &&
          !props.signUpModalVisible ? (
            <ul className="example-questions-list">
              {exampleQuestions.map((question, index) => (
                <li
                  key={index}
                  className="chat-bubble"
                  onClick={(e) => {
                    if (props.selectedCharacterObject.character != "Scenester") {
                      log.info("not sceneGPT");
                      handleDemoMessageSubmit(e, question);
                    } else {
                      setInputMessage(question);
                    }
                  }}
                >
                  <span className="chat-text">{question}</span>
                </li>
              ))}
            </ul>
          ) : (
            <ul className="example-questions-list-placeholder" />
          )}

        </div>
        {/*<div*/}
        {/*  ref={chatInputRef}*/}
        {/*  className={`${temporaryModalBool ? "no-z-index" : ""}`}*/}
        {/*>*/}
        {/* TODO: PREMIUM FUNCTIONALITY */}
        {(!props.user || (props.user && props.user.isPremium != "premium" && messages[props.selectedCharacterObject.character]?.length > 0)) && (<>
          <div className="remaining-characters">{remainingMessages} left</div>
        </>)}
        <form className="chat-input-form">
          {isOnLimitedModel && (
            <div className={"text-box"} style={{ marginBottom: "20px" }}>
              <p>You are using the limited model. We support up to 6 messages on our premium model</p>

              <button className="upgrade-button" onClick={(e) => {
                e.preventDefault();
                setIsSubscribeModalOpen(true);
              }}>
                <span className="upgrade-button-text">Upgrade</span>
              </button>

              {/* close modal button */}
              <button className="close-modal-button" onClick={(event) => {
                event.preventDefault();
                setIsSubscribeModalOpen(false);
              }}>
                <FontAwesomeIcon icon={faTimes} />
              </button>

            </div>
          )}
          <div className={"text-box"}>
            <>
              <div id="blur-overlay"></div>
              <div className={"extension-tooltip"}>
                <div>
                  <FontAwesomeIcon
                    onClick={() => {
                      toggleInputActions();
                      const blurOverlay = document.getElementById("blur-overlay");
                      if (blurOverlay) {
                        blurOverlay.classList.toggle("active");
                      }
                    }}
                    icon={isActionsVisible ? faMinus : faPlus}
                    className="plus-button"
                  />
                  {/*<CornerMaxButton />*/}
                </div>
                <div className="input-actions-container"
                     style={{ display: isActionsVisible ? "flex" : "none" }}>
                  {props.selectedCharacterObject.character !== "Scenester" && (
                    <>
                      {/*<div*/}
                      {/*  className={"side-options-buttons"}*/}
                      {/*>*/}
                      {/*  <button*/}
                      {/*    onClick={props.changeCharacterInfiniteScroll}*/}
                      {/*  >*/}
                      {/*    <FontAwesomeIcon*/}
                      {/*      icon={faShuffle}*/}
                      {/*    /></button>*/}
                      {/*</div>*/}


                      {/*<div*/}
                      {/*  className={"side-options-buttons"}*/}
                      {/*>*/}
                      {/*  <button*/}
                      {/*    onClick={props.setNextCharacter}*/}
                      {/*  >*/}
                      {/*    <FontAwesomeIcon*/}
                      {/*      icon={faCircleArrowUp}*/}
                      {/*    /></button>*/}
                      {/*</div>*/}


                      {/*<div*/}
                      {/*  className={"side-options-buttons"}*/}
                      {/*>*/}
                      {/*  <button*/}
                      {/*    onClick={props.setPreviousCharacter}*/}
                      {/*  >*/}
                      {/*    <FontAwesomeIcon*/}
                      {/*      icon={faCircleArrowDown}*/}
                      {/*    /></button>*/}
                      {/*</div>*/}


                    </>
                  )}
                  <ChatToneButton setCharacterTone={setCharacterTone} />
                  <ChatThemeButton setCharacterTheme={setCharacterTheme} />
                  <ChatLengthButton setResponseLength={setChatLength} />
                  <ChatLanguageButton setChatLanguage={setChatLanguage} />
                </div>
              </div>
            </>
            <TextareaAutosize
              style={{ resize: "none", marginLeft: "2vh" }}
              value={inputMessage}
              onChange={(e) => {
                setInputMessage(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit(e);
                }
              }}
              className="chat-input lightsweep-effect"
              placeholder={placeholderText}
              maxRows={size.height < 600 ? 2 : 3}
              onFocus={(e) => {
                // Delay scrolling to the end to ensure it works after the component updates
                setTimeout(() => {
                  e.target.scrollTop = e.target.scrollHeight;
                }, 0);
              }}
            />
            <div>
              <div className="send-button-container" style={{ display: inputMessage.trim() ? "flex" : "none" }}>
                <div
                  className="chat-submit-button"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <FontAwesomeIcon icon={faArrowUp} className="send-icon" style={{ fontSize: "18px" }} />
                </div>
              </div>

              <div className="tooltip-mic"
                   style={{ display: !inputMessage.trim() && isMicrophoneActive ? "block" : "none" }}>
                {props.selectedCharacterObject?.gender !== undefined && (
                  <>
                    <Microphone
                      insertUserMessageTranscript={insertUserMessageTranscript}
                      insertCharacterMessageTranscript={insertCharacterMessageTranscript}
                      character={props.selectedCharacterObject.character}
                      gender={props.selectedCharacterObject.gender}
                      movie={props.movie?.title || "Scenester"}
                      setRecording={setIsRecording}
                      setLimitModalVisible={props.setLimitModalVisible}
                      setAdConsentModal={props.setAdConsentModal}
                      characterTone={characterTone}
                      characterTheme={characterTheme}
                      chatLength={chatLength}
                      chatLanguage={chatLanguage}
                      setPromptedMessages={setPromptedMessages}
                      setTemporaryBlob={setTemporaryBlob}
                      actor={props.selectedCharacterObject.name}
                      session={props.session}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="credit-button-container">
              <div
                onClick={serveAdNew}
                className={isShaking ? "shake-effect" : ""}
                style={{
                  transform: "scale(2.3)",
                  transformOrigin: "center",
                  overflow: "hidden",
                  position: "relative", // Added for positioning the pseudo-element
                  width: "40px",        // Set explicit width
                  height: "40px"        // Set explicit height
                }}
              >
                <Lottie
                  options={{
                    loop: false,
                    autoplay: false,
                    animationData: creditStarAnimation,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice"
                    }
                  }}
                  height={40}
                  width={40}
                  isStopped={!isPlaying}
                />
              </div>
            </div>
          </div>
        </form>
        <div className="disclaimer-text"
             style={{ fontSize: "1.5vh", color: "#888888a5", marginTop: "1vh" }}>
          Parody disclaimer: Any resemblance is coincidental. And hilarious!
        </div>
      </div>

    </>
  );
};

export default InnerChat;