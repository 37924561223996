import React, { useCallback, useEffect, useRef, useState } from "react";
import { Howl } from "howler";
import "../../assets/css/waveform.scss";
import { getWithoutExpiry } from "../../helper/storageUtils";
import { API_URL } from "../../config";
import { toast } from "react-toastify";
import { getBlobFromLocalStorage, saveBlobToLocalStorage } from "../../helper/recordingsHandler";
import log from "loglevel";
import * as Sentry from "@sentry/react";

interface WaveformProps {
  audioUrl: string;
  isRecording?: boolean;
  blobUrl?: string;
  complete_path?: string;
  isLastMessage?: boolean;
  noAuth?: boolean;
  onError?: (error: Error) => void;
  retryCount?: number;
  retryDelay?: number;
}

const Waveform: React.FC<WaveformProps> = ({
                                             audioUrl,
                                             isRecording = false,
                                             blobUrl,
                                             isLastMessage,
                                             noAuth,
                                             complete_path,
                                             onError,
                                             retryCount = 3,
                                             retryDelay = 1000
                                           }) => {
  const [playing, setPlaying] = useState<boolean>(false);
  const [duration, setDuration] = useState<string>("0:00");
  const [animate, setAnimate] = useState(false);
  const [localAudioUrl, setLocalAudioUrl] = useState<string | null>(null);
  const soundRef = useRef<Howl | null>(null);
  const [progress, setProgress] = useState(0);
  const fetchingRef = useRef<boolean>(false);

  const fetchAudioWithRetry = useCallback(async (attempts = retryCount) => {
    if (fetchingRef.current) {
      log.info("[Waveform] Fetch already in progress, skipping...");
      return;
    }

    fetchingRef.current = true;
    log.info(`[Waveform] Starting audio fetch attempt ${retryCount - attempts + 1}/${retryCount}`);

    try {

      if (noAuth && complete_path == undefined){
        return;
      }

      const connection_url = noAuth
        ? `${API_URL}/api/audio-no-auth/${complete_path}`
        : `${API_URL}/api/audio/${audioUrl}`;

      log.info(`[Waveform] Fetching audio from: ${connection_url}`);

      const response = await fetch(connection_url, {
        headers: {
          Authorization: noAuth ? "" : `Bearer ${getWithoutExpiry("access_token") || ""}`
        }
      });

      log.info(`[Waveform] Fetch response status: ${response.status}`);

      if (!response.ok) {
        if (attempts > 0 && response.status !== 404) {
          log.warn(`[Waveform] Fetch failed, retrying in ${retryDelay}ms. Attempts remaining: ${attempts - 1}`);
          fetchingRef.current = false;
          setTimeout(() => fetchAudioWithRetry(attempts - 1), retryDelay);
          return;
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      log.info(`[Waveform] Blob received, size: ${blob.size} bytes`);

      saveBlobToLocalStorage(audioUrl, blob);
      const url = URL.createObjectURL(blob);
      log.info(`[Waveform] Created object URL: ${url}`);

      setLocalAudioUrl(url);
      loadAudio(url);
    } catch (error) {
      Sentry.captureException(error);
      log.error("[Waveform] Audio fetch error:", error);
      onError?.(error as Error);
      toast.error("Error loading audio. Please try again.");
    } finally {
      fetchingRef.current = false;
    }
  }, [audioUrl, complete_path, noAuth, retryCount, retryDelay]);

  const loadAudio = useCallback((url: string) => {
    log.info("[Waveform] Loading audio from URL:", url);

    if (soundRef.current) {
      log.info("[Waveform] Unloading previous audio instance");
      soundRef.current.unload();
    }

    soundRef.current = new Howl({
      src: [url],
      html5: true,
      format: ["mp3", "wav"],
      onload: () => {
        const audioDuration = soundRef.current?.duration() || 0;
        log.info(`[Waveform] Audio loaded, duration: ${audioDuration}s`);
        setDuration(formatDuration(audioDuration));
      },
      onloaderror: (id, error) => {
        log.error("[Waveform] Howler load error:", { id, error });
        toast.error("Error loading audio format");
      },
      onplayerror: (id, error) => {
        log.error("[Waveform] Howler play error:", { id, error });
        toast.error("Error playing audio");
      },
      onplay: () => {
        log.info("[Waveform] Audio playback started");
        setPlaying(true);
      },
      onpause: () => {
        log.info("[Waveform] Audio playback paused");
        setPlaying(false);
      },
      onend: () => {
        log.info("[Waveform] Audio playback ended");
        setPlaying(false);
        setProgress(0);
      },
      onseek: () => {
        const currentProgress = (soundRef.current?.seek() as number) / (soundRef.current?.duration() as number) * 100;
        log.info(`[Waveform] Audio seeked, progress: ${currentProgress}%`);
        setProgress(currentProgress);
      }
    });

    const progressInterval = setInterval(() => {
      if (soundRef.current && soundRef.current.playing()) {
        const currentProgress = (soundRef.current.seek() as number) / (soundRef.current.duration() as number) * 100;
        setProgress(currentProgress);
      }
    }, 100);

    return () => clearInterval(progressInterval);
  }, []);

  useEffect(() => {
    log.info("[Waveform] Component mounted/updated", { audioUrl, noAuth, complete_path });
    setAnimate(true);

    const loadFromBlob = () => {
      const cachedBlob = getBlobFromLocalStorage(audioUrl);
      if (cachedBlob) {
        log.info("[Waveform] Found cached audio blob");
        const url = URL.createObjectURL(cachedBlob);
        setLocalAudioUrl(url);
        loadAudio(url);
      } else {
        log.info("[Waveform] No cached audio found, fetching from server");
        fetchAudioWithRetry();
      }
    };

    // Temporarily bypass blob cache for testing
    // loadFromBlob();
    fetchAudioWithRetry();

    return () => {
      if (localAudioUrl) {
        log.info("[Waveform] Cleaning up object URL:", localAudioUrl);
        URL.revokeObjectURL(localAudioUrl);
      }
      if (soundRef.current) {
        log.info("[Waveform] Unloading audio instance");
        soundRef.current.unload();
      }
    };
  }, [audioUrl, blobUrl, isLastMessage, fetchAudioWithRetry, loadAudio]);

  const formatDuration = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${String(remainingSeconds).padStart(2, "0")}`;
  };

  const handlePlay = () => {
    if (soundRef.current) {
      if (playing) {
        log.info("[Waveform] Pausing audio playback");
        soundRef.current.pause();
      } else {
        log.info("[Waveform] Starting audio playback");
        soundRef.current.play();
      }
    } else {
      log.warn("[Waveform] Play attempted but no audio instance available");
    }
  };

  return (
    <div className={`parent-container ${animate ? "slide-in" : ""}`}>
      <div className="waveform-container">
        <button className="button play-button" onClick={handlePlay} disabled={isRecording}>
          {!playing ? (
            <svg className="button-icon" viewBox="5.0 0.0 50 50">
              <polygon className="triangle" points="16.67,10.00 41.67,25.00 16.67,40.00" />
            </svg>
          ) : (
            <svg className="button-icon" viewBox="0 0 60 60">
              <rect className="rectangle" x="10.0" y="15" width="10.0" height="30" />
              <rect className="rectangle" x="30.0" y="15" width="10.0" height="30" />
            </svg>
          )}
        </button>

        <div className="wave">
          <div className="progress-bar-container">
            <div
              className="progress-bar"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      </div>
      <div className="secs" style={{ fontSize: "7pt" }}>
        <div>{duration}</div>
      </div>
    </div>
  );
};

export default Waveform;