import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import CheckoutButton from "./CheckoutButton";
import { User } from "../../types/UserType";
import "../../assets/css/checkoutmodal.css";
import posthog from "posthog-js";

interface CheckoutModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: User;
  displaySource?: "account" | "homepage";
}

const CheckoutModal: React.FC<CheckoutModalProps> = ({ isOpen, onClose, user, displaySource }) => {
  const [selectedPlan, setSelectedPlan] = useState<"monthly" | "annual">("monthly");
  const modalRef = useRef<HTMLDivElement>(null);
  const featuresRef = useRef<HTMLUListElement>(null);

  const features = [
    "🧠 Persistent memory",
    "💬 Mood Settings",
    "🧪 Early beta access",
    "🤝 Priority feature requests",
    "🎭 Roleplay as Anyone",
    "🌟 VIP badge",
    "💾 Save & Share chats",
    "🎙 10 Realistic AI voices"
  ];

  useEffect(() => {
    if (isOpen && modalRef.current && featuresRef.current) {
      gsap.fromTo(modalRef.current,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 0.3, ease: "power2.out" }
      );
      gsap.fromTo(featuresRef.current.children,
        { opacity: 0, y: 10 },
        { opacity: 1, y: 0, stagger: 0.05, duration: 0.2, ease: "power2.out" }
      );
    }
  }, [isOpen]);

  const handleUpgradeClick = () => {
    gsap.to(modalRef.current, {
      scale: 1.05,
      duration: 0.2,
      ease: "power2.out",
      onComplete: () => {
        gsap.to(modalRef.current, { scale: 1, duration: 0.2, ease: "power2.in" });
      }
    });
    posthog.capture("upgrade_clicked for plan", { plan: selectedPlan });
  };

  const handlePlanSelect = (plan: "monthly" | "annual") => (event: React.MouseEvent) => {
    event.stopPropagation();
    gsap.to(`.plan-option`, { scale: 1, duration: 0.2 });
    gsap.to(`.plan-option.${plan}`, {
      scale: plan === "annual" ? 1.05 : 1.02,
      duration: 0.2
    });
    setSelectedPlan(plan);
    posthog.capture("plan_selected", { plan });
  };

  if (!isOpen) return null;

  return (
    <div className="checkout-modal-overlay" onClick={onClose}>
      <div className={`checkout-modal ${displaySource == "homepage" ? "checkout-modal-search" : ""}`} ref={modalRef}>
        <span className="close-button" onClick={onClose}>&times;</span>
        <h2>
          <img src="/full_logo.svg" alt="sceneXtras" className="scenextras-logo" />
          <span className="pro-text">MAX</span>
        </h2>
        <p className="tagline">What you get</p>
        <ul className="feature-list" ref={featuresRef}>
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
        <div className="plan-options">
          <button
            className={`plan-option monthly ${selectedPlan === "monthly" ? "selected" : ""}`}
            onClick={handlePlanSelect("monthly")}
          >
            <div className="plan-details">
              <span className="plan-name">Monthly Plan</span>
              <span className="price">Billed monthly at $9.99</span>
            </div>
            <span className="weekly-price">$2.31/week</span>
          </button>
          <button
            className={`plan-option annual ${selectedPlan === "annual" ? "selected" : ""}`}
            onClick={handlePlanSelect("annual")}
          >
            <div className="plan-details">
              <span className="plan-name">Annual Plan</span>
              <span className="price">Billed annually at $69.99</span>
            </div>
            <span className="weekly-price">$1.35/week</span>
          </button>
        </div>
        <CheckoutButton
          user={user}
          className="upgrade-button2"
          plan={selectedPlan}
          onButtonClick={handleUpgradeClick}
        >
          Upgrade Now
        </CheckoutButton>
        <p style={{ fontSize: "12px", marginTop: "20px" }}>Terms of Use & Privacy Policy</p>
      </div>
    </div>
  );
};

export default CheckoutModal;