import React, { FC, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPalette } from "@fortawesome/free-solid-svg-icons"; // Changed the icon to faPalette
import "../../assets/css/chatthemebtn.scss";
import posthog from "posthog-js";
import CornerMaxButton from "../miscs/CornerMaxButton"; // Import the CSS file

interface ChatThemeProps {
  setCharacterTheme: (theme: string) => void;
}

const ChatThemeButton: FC<ChatThemeProps> = ({ setCharacterTheme }) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState<string | null>(null);
  const themes = ["Default", "Help", "Learn", "Scene", "Song", "Poem"];
  const listRef = useRef<HTMLUListElement>(null);

  const handleClick = (e: React.FormEvent) => {
    e.preventDefault();
    setIsActive(!isActive);
    if (listRef.current) {
      listRef.current.style.height = isActive ? "0" : "300px";
    }
  };

  const handleThemeClick = (theme: string) => {
    setCharacterTheme(theme);
    posthog.capture("theme_selected", { theme });
    setSelectedTheme(theme);
    setTimeout(() => {
      setIsActive(false);
    }, 300);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target && !target.closest(".theme-list-container")) {
        setIsActive(false);
        if (listRef.current) {
          listRef.current.style.height = "300px";
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <React.Fragment>
      <div className={`theme-list-container ${isActive ? "active" : ""} side-options-buttons`}>
        <CornerMaxButton blockIsActive={false} location="options-menu">
          <button
            className="theme-more-button"
            aria-label="Menu Button"
            onClick={handleClick}
          >

            <FontAwesomeIcon
              icon={faPalette}
              className="icon-shadow"
              style={{ color: "#f4f7fa" }}
            />{" "}

          </button>
        </CornerMaxButton>
        <ul className="theme-more-button-list" ref={listRef} style={{
          display: isActive ? "block" : ""
        }}>
          {themes.map((theme, index) => (
            <CornerMaxButton blockIsActive={true} key={index} location="options-item">
              <li
                key={index}
                className={`theme-more-button-list-item ${theme === selectedTheme ? "selected" : ""}`}
                onClick={() => handleThemeClick(theme)}
              >
                <span>{theme}</span>
              </li>
            </CornerMaxButton>
          ))}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default ChatThemeButton;
