import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./../../css/CharacterCarousel.css";
import { Cast } from "../../types/CastType";
import { Movie } from "../../types/MovieType";
import { API_URL } from "../../config";

interface CharacterCarouselProps {
  cast: Cast[];
}

const CharacterCarousel: React.FC<CharacterCarouselProps> = ({ cast }) => {
  const navigate = useNavigate();
  const carouselRef = useRef<HTMLDivElement>(null);
  const [isReady, setIsReady] = useState(false);

  const getImageUrl = (character: Cast) => {
    if (!character.profile_path) {
      return `${process.env.PUBLIC_URL}/Placeholder-Portrait.jpg`;
    }

    const cleanPath = character.profile_path.replace("https://image.tmdb.org/t/p/w500", "");

    if (cleanPath.startsWith("http://") || cleanPath.startsWith("https://")) {
      // This is already a full URL (e.g., from MyAnimeList)
      return cleanPath;
    } else if (cleanPath.startsWith(API_URL)) {
      // This is a local image
      return cleanPath;
    } else {
      // This is a TMDB image path
      return `https://image.tmdb.org/t/p/w500${cleanPath}`;
    }
  };

  useEffect(() => {
    const uniqueImages = new Set(cast.map(character => getImageUrl(character)));
    
    Promise.all(
      Array.from(uniqueImages).map(src => 
        new Promise((resolve) => {
          const img = new Image();
          img.src = src;
          img.onload = () => resolve(true);
          img.onerror = () => resolve(false);
        })
      )
    ).then(() => {
      setIsReady(true);
      sessionStorage.setItem('carouselComponentReady', 'true');
    });

    return () => {
      sessionStorage.removeItem('carouselComponentReady');
    };
  }, [cast]);

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      let isDragging = false;
      let startX = 0;
      let scrollLeft = 0;

      const onMouseDown = (e: MouseEvent) => {
        isDragging = true;
        startX = e.pageX - carousel.offsetLeft;
        scrollLeft = carousel.scrollLeft;
        e.preventDefault();
      };

      const onMouseMove = (e: MouseEvent) => {
        if (!isDragging) return;
        const x = e.pageX - carousel.offsetLeft;
        const walk = (x - startX);
        carousel.scrollLeft = scrollLeft - walk;
      };

      const onMouseUp = () => {
        isDragging = false;
      };

      carousel.addEventListener("mousedown", onMouseDown);
      carousel.addEventListener("mousemove", onMouseMove);
      carousel.addEventListener("mouseup", onMouseUp);
      carousel.addEventListener("mouseleave", onMouseUp);

      return () => {
        carousel.removeEventListener("mousedown", onMouseDown);
        carousel.removeEventListener("mousemove", onMouseMove);
        carousel.removeEventListener("mouseup", onMouseUp);
        carousel.removeEventListener("mouseleave", onMouseUp);
      };
    }
  }, []);

  interface CustomCSSProperties extends React.CSSProperties {
    [key: `--${string}`]: string | number;
  }

  const customStyles: CustomCSSProperties = {
    "--speed": 550,
    "--count": cast.length.toString(),
    "--scale": 1,
    "--blur": 1,
    "--blurs": 8
  };

  const handleCharacterClick = (character: Cast) => {
    navigate("/chat", {
      state: {
        character: character,
        chat_type: "carousel",
        movie: { title: character.from_movie, cast: [character] } as Movie,
        cast: [character]
      }
    });
  };

  const filledCast = [...cast, ...cast];

  if (!isReady) return null;

  return (
    <div 
      className="carousel-resizable fade-in" 
      style={customStyles}
    >
      <div className="carousel-blur-container">
        <div className="carousel-blur carousel-blur--left">
          {new Array(8).fill(null).map((_, index) => (
            <div key={index} style={{ "--index": index } as CustomCSSProperties} />
          ))}
        </div>
        <div 
          className="carousel-container" 
          data-translate="items" 
          data-direction="horizontal" 
          ref={carouselRef}
        >
          <ul className="carousel-list">
            {filledCast.map((character, index) => (
              <li
                key={`${character.credit_id}-${index}`}
                className={`carousel-item ${character.isFromMessages ? "from-messages silver" : ""}`}
                style={{ "--index": index % cast.length } as CustomCSSProperties}
                onClick={() => handleCharacterClick(character)}
                title={character.character}
              >
                <div className="carousel-avatar-container">
                  <img
                    className="character-avatar"
                    src={getImageUrl(character)}
                    alt={character.name}
                    onError={(e) => {
                      const imgElement = e.target as HTMLImageElement;
                      imgElement.src = `${process.env.PUBLIC_URL}/Placeholder-Portrait.jpg`;
                    }}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="carousel-blur carousel-blur--right">
          {new Array(8).fill(null).map((_, index) => (
            <div key={index} style={{ "--index": index + 1 } as CustomCSSProperties} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CharacterCarousel;
