import { useState, useEffect } from 'react';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const detectMobile = () => {
      // 1. Check for touch capability
      const hasTouch =
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0;

      // 2. Check user agent string for mobile devices
      const userAgent = navigator.userAgent.toLowerCase();
      const mobileKeywords = [
        'android',
        'iphone',
        'ipad',
        'ipod',
        'blackberry',
        'windows phone',
        'opera mini',
        'mobile',
        'tablet'
      ];
      const isMobileDevice = mobileKeywords.some(keyword =>
        userAgent.includes(keyword)
      );

      // 3. Check screen characteristics
      const isPortrait = window.innerHeight > window.innerWidth;
      const hasSmallScreen = window.innerWidth < 768;

      // Combine all factors to make the final decision
      // Device is considered mobile if it:
      // - Has touch capability AND (is a mobile device OR has a small screen in portrait mode)
      setIsMobile(hasTouch && (isMobileDevice || (hasSmallScreen && isPortrait)));
    };

    // Check initially
    detectMobile();

    // Add event listeners for changes
    window.addEventListener('resize', detectMobile);
    window.addEventListener('orientationchange', detectMobile);

    // Cleanup
    return () => {
      window.removeEventListener('resize', detectMobile);
      window.removeEventListener('orientationchange', detectMobile);
    };
  }, []);

  return isMobile;
};


export default useIsMobile;