import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import loadingAnimation from "../../assets/lotties/loading-animation.gif";

interface LoadingProps {
  timeoutRoute?: string;
  timeoutDuration?: number;
}

function Loading({ timeoutRoute, timeoutDuration = 3000 }: LoadingProps) {
  const INTERVAL_TIME_SECONDS = 3;
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>("");

  const loadingMessages = [
    "Downloading every movie reference. Ever.",
    "Upgrading sarcasm filters to 'unhinged'",
    "Installing sense of humor...",
    "Disabling AI's politeness protocols… sry in advance!",
    "Maxing out our AI's eccentricity settings.",
    "Please ignore any strange noises from the AI...",
    "If our AI starts questioning reality, just go with it.",
    "Downloading catchphrases from canceled shows...",
    "Disabling AI's politeness protocols. This is Sparta!",
    "Training AI to differentiate awkward and dramatic silences...",
    "Running Doomsday scenario simulations.",
    "Activating self-destruct. Hasta la vista, baby!",
    "Researching most quotable lines...",
    "Scanning for plot holes...",
    "Analyzing tropes...",
    "Suspensful Music playing...",
    "Googling celebrity trivia...",
    "Exploring optimal movie marathon snacks...",
    "Our AI is punching above its 'weights'...",
    "Dramatic Violins playing.",
    "Tip: Try out the shuffle button for luck",
    "Finding a cure for boredom...",
    "Making sure all references are up to date...",
    "Try asking Characters to tell you thin"
  ];

  useEffect(() => {
    function pickRandomMessage() {
      const randomMsg =
        loadingMessages[Math.floor(Math.random() * loadingMessages.length)];
      setMessage(randomMsg);
    }

    pickRandomMessage();

    const messageInterval = setInterval(
      pickRandomMessage,
      INTERVAL_TIME_SECONDS * 1000
    );

    let timeoutId: NodeJS.Timeout | undefined;
    if (timeoutRoute) {
      timeoutId = setTimeout(() => {
        navigate(timeoutRoute);
      }, timeoutDuration);
    }

    return () => {
      clearInterval(messageInterval);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutRoute, timeoutDuration, navigate, loadingMessages]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
        backgroundColor: "rgba(0, 0, 0, 0.5)"
      }}
    >
      <img
        src={loadingAnimation}
        alt="Loading..."
        style={{ height: "2rem", width: "2rem" }}
      />
      <div style={{ padding: "1rem", color: "white", fontSize: "small" }}>
        {message}
      </div>
    </div>
  );
}

export default Loading;