import React, { useEffect, useRef, useState } from "react";
import Account from "./Account";
import { User } from "../../types/UserType";
import posthog from "posthog-js";
import { toast } from "react-toastify";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import log from "loglevel";
import * as Sentry from "@sentry/react";
import { AuthenticatedUser, convertUserToAuthenticatedUser, fetchUserForSupabaseNew } from "../../helper/authHelper";
import Seo from "../../helper/Seo";
import "../../assets/css/auth.css";

interface ProfilePageProps {
  supabase: SupabaseClient;
  session?: Session | null;
  setLoggedInUser?: (user: AuthenticatedUser | undefined) => void;
}

const Profile: React.FC<ProfilePageProps> = ({ ...props }) => {
  const DISCORD_LINK = process.env.REACT_APP_DISCORD_URL || "https://discord.com/invite/AscUxE2NEC";
  const [sceneUser, setSceneUser] = useState<User | null>(null);
  const [user, setUser] = useState<AuthenticatedUser | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const toastShownRef = useRef(false);

  const checkUserExists = async () => {
    log.info(props.session);
    if (props.session && props.session.user.email) {
      try {
        const email = props.session.user.email.toLowerCase();

        const { data, error } = await props.supabase
          .from("allowed_users")
          .select("confirmed")
          .eq("email", email)
          .maybeSingle();


        if (error) throw error;
        if (!data?.confirmed) {

          await handleForcedWhitelistLogout();
        } else {
          await fetchUserForSupabaseNew(props.session, convertUserToAuthenticatedUser, setUser);
        }

      } catch (error) {
        Sentry.captureException(error);
        log.error(error);
        await handleForcedWhitelistLogout();
      }
    }
  };

  useEffect(() => {
    checkUserExists();
  }, [props.session?.user.id, props.session?.user.email]);


  const handleForcedWhitelistLogout = async () => {
    if (!toastShownRef.current) {
      await props.supabase.auth.signOut();
      toast(
        <div>
          Your account is not whitelisted, request an invite on Discord:
          <a href={DISCORD_LINK} target="_blank" rel="noopener noreferrer">
            link
          </a>
          !
        </div>,
        {
          autoClose: false,
          closeOnClick: false
        }
      );
      toastShownRef.current = true;
    }
  };

  const signOutWrapper = async () => {
    posthog.reset();
    if (props.setLoggedInUser) {
      props.setLoggedInUser(undefined);
    }
    setUser(null);
  };

  return (
    <>
      <Seo
        title={"Profile - Scenextras"}
        description={"Profile - Scenextras - manage your account."}
        url={`https://chat.scenextras.com/profile`}
        image={"https://framerusercontent.com/images/mvyirly4lm7ajnERA4s7OwV59Nw.png"}
      />

      <style>
        {`
          .auth-container {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 100vh;
            background: linear-gradient(135deg, #1a1a1a, #2a2a2a);
            padding: 20px;
          }

          .auth-content {
            background-color: rgba(0, 0, 0, 0.75);
            border-radius: 20px;
            padding: 30px;
            box-shadow: 0 0 30px rgba(255, 193, 7, 0.15), 0 0 60px rgba(76, 175, 80, 0.08);
            max-width: 400px;
            width: 100%;
            text-align: center;
            backdrop-filter: blur(20px);
            border: 1px solid rgba(255, 255, 255, 0.1);
          }

          .auth-logo {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
          }

          .auth-logo img {
            width: 120px;
            height: auto;
          }

          .auth-title {
            color: #ff8c00;
            font-size: 28px;
            font-weight: 700;
            margin-bottom: 8px;
          }

          .auth-subtitle {
            color: rgba(255, 255, 255, 0.7);
            font-size: 16px;
            margin-bottom: 24px;
          }

          .error-box {
            background-color: rgba(255, 0, 0, 0.1);
            color: #ff4444;
            padding: 12px;
            border-radius: 8px;
            margin-top: 16px;
            font-size: 14px;
          }

          @media (max-width: 480px) {
            .auth-content {
              padding: 20px;
            }

            .auth-title {
              font-size: 24px;
            }

            .auth-subtitle {
              font-size: 14px;
            }

            .auth-logo img {
              width: 100px;
            }
          }
        `}
      </style>

      {!props.session ? (
        <div className="auth-container">
          <div className="auth-content">
            <div className="auth-logo">
              <img src="https://framerusercontent.com/images/mvyirly4lm7ajnERA4s7OwV59Nw.png" alt="Scenextras Logo" />
            </div>
            <h2 className="auth-title">Sign into your account</h2>
            <p className="auth-subtitle">Your Characters are waiting</p>
            <Auth
              supabaseClient={props.supabase}
              appearance={{
                theme: ThemeSupa,
                style: {
                  button: {
                    background: "white",
                    color: "#333",
                    borderRadius: "20px",
                    fontSize: "16px",
                    fontWeight: "600",
                    padding: "12px",
                    marginBottom: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                  },
                  input: {
                    background: "rgba(255, 255, 255, 0.1)",
                    color: "white",
                    borderRadius: "10px",
                    border: "none",
                    padding: "12px",
                    marginBottom: "12px"
                  },
                  label: {
                    color: "rgba(255, 255, 255, 0.7)",
                    fontSize: "14px",
                    marginBottom: "4px"
                  },
                  anchor: {
                    color: "#ff8c00",
                    fontSize: "14px"
                  }
                }
              }}
              providers={["google"]}
              redirectTo={process.env.REACT_APP_AUTH_REDIRECT_URL}
              view="magic_link"
              showLinks={false}
            />
            {error && <div className="error-box">{error}</div>}
          </div>
        </div>
      ) : (
        <Account
          user={user}
          signOut={signOutWrapper}
          session={props.session}
          supabase={props.supabase}
        />
      )}
    </>
  );
};

export default Profile;
