import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { PostHogProvider } from "posthog-js/react";
import log from "loglevel";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { currentEnv, isAnalyticsEnabled } from "./config";
import SentryProvider from "./helper/SentryProvider";
import "./assets/css/index.css";
import { validateEnvVariables } from "./helper/envValidator";
import * as Sentry from "@sentry/react";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST
};

if (isAnalyticsEnabled()) {
  log.setLevel("silent");
} else {
  log.enableAll();
}

validateEnvVariables([
  "REACT_APP_SUPABASE_KEY",
  "REACT_APP_SUPABASE_URL",
  "REACT_APP_ENV",
  "REACT_APP_AUTH_REDIRECT_URL",
  "REACT_APP_BACKEND_ENV",
  "REACT_APP_SENTRY_DSN"
]);

const rootElement = document.getElementById("root");

if (!rootElement) {
  throw new Error("Root element not found");
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: currentEnv,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    })
  ],
  tracesSampleRate: currentEnv === "production" ? 0.2 : 1.0
});


const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}
      >
        <SentryProvider>
          <App />
        </SentryProvider>
      </PostHogProvider>
    </BrowserRouter>
  </React.StrictMode>
);

/**
 * Report Web Vitals
 * For more information, visit: https://bit.ly/CRA-vitals
 */
reportWebVitals();