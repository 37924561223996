import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PaymentSuccessful: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/search");
    }, 2000);
  }, []);

  return (
    <div style={{ textAlign: "center", padding: "20px", color: "green" }}>
      <h1>Payment Successful!</h1>
      <p>Thank you for your purchase. Your payment was processed successfully.</p>
    </div>
  );
};

export default PaymentSuccessful;