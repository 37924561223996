import React, { FC, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import posthog from "posthog-js";
import "../../assets/css/chattonebtn.scss";
import CornerMaxButton from "../miscs/CornerMaxButton";

interface ChatMenuProps {
  setCharacterTone: (tone: string) => void;
}

const ChatToneButton: FC<ChatMenuProps> = ({ setCharacterTone }) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedTone, setSelectedTone] = useState<string | null>(null);
  const tones = [
    "Default",
    "Friendly",
    "Excited",
    "Deep",
    "Flirty",
    "Wild",
    "Funny",
    "Formal",
    "Emotional",
    "Sarcastic",
    "Witty",
    "Cool",
    "Aggressive",
    "Caring"
  ];
  const listRef = useRef<HTMLUListElement>(null);

  const handleClick = (e: React.FormEvent) => {
    e.preventDefault();
    setIsActive(!isActive);
    if (listRef.current) {
      listRef.current.style.height = isActive ? "0" : "300px";
    }
  };

  const handleToneClick = (tone: string) => {
    setCharacterTone(tone);
    posthog.capture("tone_selected", { tone });
    setSelectedTone(tone);
    setTimeout(() => {
      setIsActive(false);
    }, 300);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target && !target.closest(".tone-list-container")) {
        setIsActive(false);
        if (listRef.current) {
          listRef.current.style.height = "300px";
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`tone-list-container ${isActive ? "active" : ""} side-options-buttons`}
    >
      <CornerMaxButton blockIsActive={false} location="options-menu">
        <button
          className="tone-more-button"
          aria-label="Menu Button"
          onClick={(e) => handleClick(e)}
        >

          <FontAwesomeIcon
            icon={faCommentDots}
            className="icon-shadow"
            style={{ color: "#f4f7fa" }}
          />

        </button>
      </CornerMaxButton>
      <ul
        className="tone-more-button-list"
        ref={listRef}
        style={{
          display: isActive ? "block" : ""
        }}
      >
        {tones.map((tone, index) => (
          <CornerMaxButton blockIsActive={true} key={index} location="options-item">
            <li
              key={index}
              className={`tone-more-button-list-item ${
                tone === selectedTone ? "selected" : ""
              }`}
              onClick={() => handleToneClick(tone)}
            >
              <span className={`emoji ${tone.toLowerCase()}`}></span>
              <span>{tone}</span>
            </li>
          </CornerMaxButton>
        ))}
      </ul>
    </div>
  );
};

export default ChatToneButton;
