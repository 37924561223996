import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import logo from "../../assets/img/scenextras-full-logo-1@2x.png";

const LoadingScreen = () => {
  const logoRef = useRef(null);
  const screenRef = useRef(null);

  useEffect(() => {
    const heartbeat = gsap.timeline({ repeat: 2, repeatDelay: 0.6 })
      .to(logoRef.current, {
        scale: 1.1,
        duration: 0.1,
        ease: "power1.inOut"
      })
      .to(logoRef.current, {
        scale: 1,
        duration: 0.1,
        ease: "power1.inOut"
      });

    gsap.set(logoRef.current, { opacity: 1 });

    const fadeOut = () => {
      gsap.to(screenRef.current, {
        opacity: 0,
        duration: 0.5,
        ease: "power2.inOut",
        onComplete: () => {
          if (screenRef.current) {
            screenRef.current.style.display = "none";
          }
        }
      });
    };

    const timer = setTimeout(fadeOut, 2000);

    return () => {
      clearTimeout(timer);
      heartbeat.kill();
    };
  }, []);

  return (
    <div ref={screenRef} className="loading-screen"
         style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", opacity: 1 }}>
      <img ref={logoRef} className="logo" src={logo} alt="Full Logo" style={{ width: "200px", height: "auto" }} />
    </div>
  );
};

export default LoadingScreen;
