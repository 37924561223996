import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import loadingAnimation from "../../assets/lotties/loading-animation.gif";
import "./../../css/CaptureLoading.css";

interface LoadingProps {
  timeoutRoute?: string;
  timeoutDuration?: number;
}

function CaptureLoading({ timeoutRoute, timeoutDuration = 3000 }: LoadingProps) {
  const INTERVAL_TIME_SECONDS = 3;
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>("");

  const loadingMessages = [
    "JARVIS: Analyzing scene composition...",
    "K.I.T.T.: Scanning for character profiles",
    "HAL 9000: Observing human interactions",
    "R2-D2: Beep boop... decoding dialogue",
    "TARS: Calculating humor settings",
    "FRIDAY: Mapping narrative structure",
    "Baymax: Detecting emotional context",
    "GLaDOS: Testing for plot holes",
    "EDI: Calibrating character motivations",
    "Samantha: Interpreting visual metaphors",
    "Vision: Synthesizing scene elements",
    "GERTY: Evaluating character arcs",
    "Mother: Extrapolating plot trajectories",
    "Skynet: Identifying key plot points",
    "VIKI: Logically analyzing character decisions"
  ];

  useEffect(() => {
    function pickRandomMessage() {
      const randomMsg =
        loadingMessages[Math.floor(Math.random() * loadingMessages.length)];
      setMessage(randomMsg);
    }

    pickRandomMessage();

    const messageInterval = setInterval(
      pickRandomMessage,
      INTERVAL_TIME_SECONDS * 1000
    );

    let timeoutId: NodeJS.Timeout | undefined;
    if (timeoutRoute) {
      timeoutId = setTimeout(() => {
        navigate(timeoutRoute);
      }, timeoutDuration);
    }

    return () => {
      clearInterval(messageInterval);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutRoute, timeoutDuration, navigate, loadingMessages]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
        backgroundColor: "rgba(0, 0, 0, 0.5)"
      }}
    >
      <img
        src={loadingAnimation}
        alt="Loading..."
        className="slide-in" /* Apply the bounce-in class */
        style={{ height: "2rem", width: "2rem" }}
      />
      <div className="swipe-text-container slide-in">
        <span className="swipe-text">{message}</span>
      </div>
    </div>
  );
}

export default CaptureLoading;
