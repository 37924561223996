import "./assets/css/app.css";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import React, { Suspense, useEffect, useRef, useState, lazy } from "react";
import { setWithExpiry } from "./helper/storageUtils";
import { fetchVersion, getTimeUntilRefresh } from "./api/apiClient";
import { ToastContainer } from "react-toastify";
import { Session } from "@supabase/supabase-js";
import { gsap } from "gsap";
import LoadingScreen from "./components/miscs/LoadingScreen";
import Profile from "./components/user/Profile";
import BottomMenuBar from "./components/template/BottomMenuBar";
import Chat from "./components/chat/Chat";

import PaymentSuccessful from "./components/payments/PaymentSuccessful";
import PaymentFailed from "./components/payments/PaymentFailed";
import log from "loglevel";
import * as Sentry from "@sentry/react";
import StyledWaitlistComponent from "./components/user/WaitlistComponent";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import {
  AuthenticatedUser,
  convertAuthenticatedUserToUser,
  convertUserToAuthenticatedUser,
  fetchUserForSupabaseNew
} from "./helper/authHelper";
import VoiceChat from "./components/chat/VoiceChat";
import VoiceChatVapi from "./components/chat/VoiceChatVapi";
import ChatExport from "./components/chat/ChatExport";
import NewChat from "./components/chat/NewChat";
import CameraCaptureSearch from "./components/miscs/CameraCaptureSearch";
import ErrorPage from "./components/miscs/ErrorPage";
import UnsubscribePage from "./components/miscs/UnsubscribePage";
import CheckoutModal from "./components/payments/CheckoutModal";
import { ModalContext } from "./components/modals/ModalContext";
import HybridSystemProvider from "./components/user/HybridSystem";
import Search from "./components/search/Search";
import ChatRouter from "./components/chat/ChatRouter";
import { supabase } from "./helper/supabase_client";
import useExportViewTracker from "./components/hooks/useExportViewTracker";
import { PostList } from "./components/export/PostList";

// const TemporaryHome = lazy(() => import("./components/miscs/TemporaryHome"));
// const PaymentSuccessful = lazy(() => import("./components/payments/PaymentSuccessful"));
// const PaymentFailed = lazy(() => import("./components/payments/PaymentFailed"));
// const StyledWaitlistComponent = lazy(() => import("./components/user/WaitlistComponent"));
// const VoiceChat = lazy(() => import("./components/chat/VoiceChat"));
// const VoiceChatVapi = lazy(() => import("./components/chat/VoiceChatVapi"));
// const ChatExport = lazy(() => import("./components/chat/ChatExport"));
// const NewChat = lazy(() => import("./components/chat/NewChat"));
// const CameraCaptureSearch = lazy(() => import("./components/miscs/CameraCaptureSearch"));
// const ErrorPage = lazy(() => import("./components/miscs/ErrorPage"));
// const UnsubscribePage = lazy(() => import("./components/miscs/UnsubscribePage"));
// const ChatRouter = lazy(() => import("./components/chat/ChatRouter"));
// // const Search = lazy(() => import("./components/search/Search"));
// const Profile = lazy(() => import("./components/user/Profile"));
// const Chat = lazy(() => import("./components/chat/Chat"));


function App() {
  useExportViewTracker();


  const location = useLocation();
  const hasRun = useRef(false);
  const navigate = useNavigate();
  const isFirstRender = useRef(true);
  const [userUUID, setUserUUID] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<AuthenticatedUser | undefined>(undefined);
  const [session, setSession] = useState<Session | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isAppReady, setIsAppReady] = useState(false);

  useEffect(() => {
    let isMounted = true; // Flag to prevent state updates on unmounted component

    const getSession = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();
      if (error) {
        console.error("Error getting session:", error);
      } else if (isMounted) {
        console.log("Initial session:", session);
        setSession(session);
        setLoading(false);
      }
    };

    getSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      console.log("Auth state changed, new session:", session);
      setSession(session);
      fetchUserForSupabaseNew(session, convertUserToAuthenticatedUser, setUser);
    });

    return () => {
      isMounted = false;
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (hasRun.current) return;
    hasRun.current = true;

    fetchVersion();
    fetchAndStoreTimestamp();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  const fetchAndStoreTimestamp = async () => {
    try {
      const refreshData = await getTimeUntilRefresh();
      if (refreshData) {
        const futureTimestamp = refreshData.time_until_refresh * 1000;
        setWithExpiry("refreshTimestamp", futureTimestamp, 60000);
      }
    } catch (error) {
      Sentry.captureException(error);
      log.error(error);
    }
  };

  // Modify preload sequence
  useEffect(() => {
    const preloadAssets = async () => {
      try {
        // First preload critical assets
        await Promise.all([
          new Promise(resolve => {
            const img = new Image();
            img.src = '/full_logo.svg';
            img.onload = resolve;
            img.onerror = resolve; // Don't hang on error
          }),
          new Promise(resolve => {
            const img = new Image();
            img.src = '/background.svg';
            img.onload = resolve;
            img.onerror = resolve;
          })
        ]);

        // Initialize GSAP
        gsap.config({
          force3D: true,
          nullTargetWarn: false
        });

        // Set app as ready immediately after assets load
        setIsAppReady(true);
        
        // Let components handle their own loading states
        sessionStorage.setItem('assetsPreloaded', 'true');
      } catch (error) {
        console.error('Asset preloading failed:', error);
        // Still set ready to prevent blank screen
        setIsAppReady(true);
      }
    };

    preloadAssets();
  }, []);

  // Optimize page transitions
  useEffect(() => {
    if (location.pathname !== "/" && !isFirstRender.current) {
      const tl = gsap.timeline({
        defaults: { 
          duration: 0.3,
          ease: "power2.out",
          clearProps: "all"
        }
      });

      tl.add("start")
        .fromTo(".main-content",
          { 
            opacity: 0,
            y: 20,
            scale: 0.98,
          },
          {
            opacity: 1, 
            y: 0,
            scale: 1,
          }, "start")
        .fromTo(".fade-in",
          {
            opacity: 0,
            y: 10,
          },
          {
            opacity: 1,
            y: 0,
            stagger: 0.05,
          }, "start+=0.1");
    }
  }, [location]);

  useEffect(() => {
    document.documentElement.style.scrollBehavior = "smooth";
  }, []);

  // Optimize click animation with transform instead of backgroundColor
  const handleItemClick = (e: React.MouseEvent) => {
    const target = e.currentTarget as HTMLElement;
    gsap.to(target, {
      scale: 0.95,
      duration: 0.1,
      force3D: true,
      yoyo: true,
      repeat: 1,
      ease: "power2.out"
    });
  };

  // Optimize background handling
  const svgBackground = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/background.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    height: "100%",
    willChange: "transform", // Optimize background rendering
    transform: "translateZ(0)" // Force GPU acceleration
  };

  if (!isAppReady || loading) {
    return <LoadingScreen />;
  }

  // const feedback = feedbackIntegration({
  //   // Disable injecting the default widget
  //   autoInject: true,
  // });
  //
  // const widget = feedback.createWidget();
  const excludedRoutes = ["/waitlist", "/export"];

  return (
    <>
      {/*<Sentry.ErrorBoundary fallback={<p>An error has occurred</p>} showDialog>*/}
      <ModalContext.Provider value={{ openModal, user }}>
        <HybridSystemProvider userEmail={user?.email}>
          <ToastContainer />
          {isModalOpen && user && (
            <CheckoutModal
              isOpen={isModalOpen}
              onClose={closeModal}
              user={convertAuthenticatedUserToUser(user)}
              displaySource={"account"}
            />
          )}
          <div className="App" style={svgBackground}>
            <Suspense fallback={<LoadingScreen />}>
              <div className="main-content">
                <Routes>
                  <Route path="/" element={<Navigate to="/search" replace />} />
                  <Route path="/profile"
                         element={<Profile supabase={supabase} session={session} setLoggedInUser={setUser} />} />
                  <Route
                    path="/search"
                    element={<ProtectedRoute component={Search} supabase={supabase} session={session} user={user} />}
                  />
                  <Route
                    path="/scenario"
                    element={<ProtectedRoute component={PostList} supabase={supabase} session={session} user={user} />}
                  />
                  <Route
                    path="/chat"
                    element={
                      <ProtectedRoute
                        component={Chat}
                        supabase={supabase}
                        session={session}
                        user={user}
                        chat_type="popular"
                      />
                    }
                  />
                  <Route
                    path="/chat/:token"
                    element={
                      <ProtectedRoute
                        component={ChatRouter}
                        supabase={supabase}
                        session={session}
                        user={user}
                      />
                    }
                  />
                  <Route path="/payment-success" element={<PaymentSuccessful />} />
                  <Route path="/waitlist" element={<StyledWaitlistComponent supabase={supabase} />} />
                  <Route path="/payment-failure" element={<PaymentFailed />} />
                  <Route path="/voice-chat" element={<VoiceChat />} />
                  <Route path="/scene-camera" element={<CameraCaptureSearch />} />
                  <Route path="/voice-chat-vapi" element={<VoiceChatVapi />} />
                  <Route path="/new_chat" element={<NewChat />} />
                  <Route path="/api/email/unsubscribe/:token" element={<UnsubscribePage />} />
                  <Route
                    path="/export"
                    element={<ChatExport user={user} />}
                  />
                  <Route
                    path="/export/:exportId"
                    element={<ChatExport />}
                  />
                  <Route path="*" element={<ErrorPage />} />
                </Routes>
              </div>
            </Suspense>
            {location.pathname !== "/waitlist" && !location.pathname.startsWith("/export") && <BottomMenuBar />}

          </div>
        </HybridSystemProvider>
      </ModalContext.Provider>
      {/*</Sentry.ErrorBoundary>*/
      }
    </>
  );
}


export default App;