import React from "react";
import { useNavigate } from "react-router-dom";
import "../../css/AdConsentModal.scss";
import { setWithoutExpiry } from "../../helper/storageUtils";

interface AdConsentModalProps {
  setVisibility: (e: any) => void;
}

const AdConsentModal: React.FC<AdConsentModalProps> = ({ setVisibility }) => {
  const navigate = useNavigate();

  const handleAccept = () => {
    setWithoutExpiry("adConsent", true);
    setVisibility(false);
  };

  return (
    <div className="ad-consent-modal">
      <div className="modal-content">
        <div className="modal-header">
          <img src="/full_logo.svg" alt="Logo" className="logo" />
          <button className="close-btn" onClick={() => setVisibility(false)}>
            &times;
          </button>
          <h2>Keep Scenextras Free</h2>
        </div>
        <div className="modal-body">
          <p>
            To continue enjoying our service for free, we show occasional ads.
            This helps us maintain our service and improve it continuously.
          </p>
          <ul>
            <li>
              <div className="loader"></div>
              <span>Viewing an ad: 1 credit</span>
            </li>
            <li>
              <div className="loader"></div>
              <span>Clicking an ad: 3 credits</span>
            </li>
          </ul>
          <hr />
          <p>
            Do you agree to see ads in exchange for keeping the service free?
          </p>
        </div>
        <div className="modal-footer">
          <button onClick={handleAccept} className="accept-btn">
            Yes, I Agree
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdConsentModal;
