import log from "loglevel";
import { useState } from "react";
import * as Sentry from "@sentry/react";

export const RECENT_CHARACTERS_KEY = "recentCharacters";


export const setWithExpiry = (
  key: string,
  value: any,
  ttl: number | null = null
) => {
  if (localStorage) {
    const now = new Date();
    const item = {
      value: value,
      expiry: ttl ? now.getTime() + ttl : null
    };
    const packaged_item = JSON.stringify(item);
    // log.info("SETTING TYPE: " + typeof packaged_item + " SETTING VALUE: " + packaged_item + " KEY: " + key);
    localStorage.setItem(key, packaged_item);
  } else {
    return null;
  }
};

export const getWithExpiry = (key: string): any => {
  if (localStorage) {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (item.expiry && now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }

    return item.value;
  } else {
    return null;
  }
};

export const getObjectFromLocal = (key: string): any => {
  if (localStorage) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    return itemStr;
  } else {
    return null;
  }
};

export const getWithoutExpiry = (key: string): any => {
  if (localStorage) {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    return item.value;
  } else {
    return null;
  }
};

export const setWithoutExpiry = (key: string, value: any) => {
  if (localStorage) {
    const item = {
      value: value
    };
    localStorage.setItem(key, JSON.stringify(item));
  } else {
    return false;
  }
};

export const incrementStorageItem = (key: string) => {
  if (localStorage) {
    const itemStr = localStorage.getItem(key);
    if (itemStr) {
      const item = JSON.parse(itemStr);
      item.value = item.value + 1;
      localStorage.setItem(key, JSON.stringify(item));
    } else {
      const item = {
        value: 1
      };
      localStorage.setItem(key, JSON.stringify(item));
    }
  } else {
    return false;
  }
};

export const removeItem = (key: string) => {
  if (localStorage) {
    localStorage.removeItem(key);
  }
};

export const clearMessages = () => {
  if (localStorage) {
    localStorage.removeItem("messages");
  }
};

export const clearCharactersIntro = () => {
  if (localStorage) {
    localStorage.removeItem("introducedCharacters");
  }
};


function useLocalStorage<T>(key: string, initialValue: T): [T, (value: T) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      Sentry.captureException(error);
      log.error(error);
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      Sentry.captureException(error);
      log.error(error);
    }
  };

  return [storedValue, setValue];
}

function removeAdminMessages(messagesCollection: Record<string, any[]>): Record<string, any[]> {
  const cleanedMessages: Record<string, any[]> = {};

  for (const [character, messages] of Object.entries(messagesCollection)) {
    cleanedMessages[character] = messages.filter(message => {
      if (typeof message.content === "string") {
        return !message.content.includes("[ADMIN]") &&
          !message.content.includes("[STAY IN CHARACTER]") &&
          !message.content.includes("chat_input.character:") &&
          !message.content.includes("chat_input.series:");
      }
      return true;
    });
  }

  return cleanedMessages;
}

export function removeAdminMessagesFromLocalStorage(key = "messages"): void {
  const messagesCollection = getWithoutExpiry(key);

  if (messagesCollection) {
    const cleanedMessages = removeAdminMessages(JSON.parse(messagesCollection));
    setWithoutExpiry(key, JSON.stringify(cleanedMessages));
  }
}

export default useLocalStorage;
