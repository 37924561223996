import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import log from "loglevel";

interface ProtectedRouteProps {
  component: React.ComponentType<any>;
  supabase: SupabaseClient;
  session: Session | null;
  path?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps & { [key: string]: any }> = ({
                                                                                  component: Component,
                                                                                  supabase,
                                                                                  session,
                                                                                  path,
                                                                                  ...rest
                                                                                }) => {
  const componentName = Component.displayName || Component.name || "UnknownComponent";

  useEffect(() => {
    log.info(
      `ProtectedRoute: Attempting to access ${componentName}`,
      {
        component: componentName,
        isAuthenticated: !!session,
        timestamp: new Date().toISOString()
      }
    );

    if (!session) {
      log.info(
        `ProtectedRoute: Unauthorized access attempt to ${componentName}`,
        {
          component: componentName,
          timestamp: new Date().toISOString()
        }
      );
    }
  }, [session, path, componentName]);

  if (!session || !session.access_token) {
    log.info("ProtectedRoute: Redirecting to login page from", componentName);
    return <Navigate to="/profile" />;
  }

  log.info(
    `ProtectedRoute: Rendering protected component ${componentName}`
  );
  return <Component supabase={supabase} session={session} {...rest} />;
};

export default ProtectedRoute;