import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getWithoutExpiry, RECENT_CHARACTERS_KEY } from "../../helper/storageUtils";
import { Movie } from "../../types/MovieType";
import { Cast } from "../../types/CastType";
import log from "loglevel";
import { useBackgroundSync } from "../../hooks/BackgroundSyncHook";
import LoadingScreen from "../miscs/LoadingScreen";

const ChatRouter: React.FC = () => {
  const hasSyncedRef = useRef(false);
  log.debug("ChatRouter component rendered");
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();
  const [isLoading, setIsLoading] = useState(true);

  const { manualSyncSingle, isSyncing } = useBackgroundSync({
    manualSyncOnly: true,
    manualSyncPerToken: token
  });
  if (token) {
    useEffect(() => {
      if (!hasSyncedRef.current) {
        hasSyncedRef.current = true;
        manualSyncSingle(token).then(() => {
          navigate_to_chat();
        }).catch(error => {
          console.error("Error during initial sync:", error);
        });

      }
    }, [manualSyncSingle]);
  }

  function navigate_to_chat() {
    log.debug("ChatRouter: useEffect triggered");
    log.debug("Token received:", token);

    const findCharacterByToken = (searchToken: string) => {
      log.debug("Searching for character with token:", searchToken);
      const allKeys = Object.keys(localStorage);
      const sessionTokenKeys = allKeys.filter(key => key.startsWith("X-Session-Token-"));
      log.debug("Session token keys found:", sessionTokenKeys);

      for (const key of sessionTokenKeys) {
        const value = getWithoutExpiry(key);
        log.debug("Checking key:", key, "Value:", value);
        if (value && value === searchToken) {
          const character = key.replace("X-Session-Token-", "");
          log.debug("Character found:", character);
          return character;
        }
      }
      log.warn("No matching characte found for token");
      return null;
    };

    const character = token ? findCharacterByToken(token) : null;
    log.debug("Character after search:", character);

    if (character) {
      const recentCharacters = getWithoutExpiry(RECENT_CHARACTERS_KEY) || [];
      log.debug("Recent characters:", recentCharacters);
      log.debug("Recent characters:", recentCharacters);
      const foundCharacter = recentCharacters.find((c: Cast) => c.character === character);
      log.debug("Found character in recent characters:", foundCharacter);

      log.debug("Found character in recent characters:", foundCharacter);

      if (foundCharacter) {
        log.info("Navigating to chat with character:", foundCharacter);
        navigate("/chat", {
          state: {
            character: foundCharacter,
            chat_type: "carousel",
            movie: { title: foundCharacter.from_movie, cast: [foundCharacter] } as Movie,
            cast: [foundCharacter]
          },
          replace: true
        });
      } else {
        log.warn("Character not found in recent characters, navigating to home");
        navigate("/", { replace: true });
      }
    } else {
      log.warn("Invalid or missing token, navigating to home");
      navigate("/", { replace: true });
    }

    setIsLoading(false);
    log.debug("ChatRouter: Loading complete");
  }

  useEffect(() => {
    // navigate_to_chat();
  }, [token, navigate]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  log.debug("ChatRouter: Rendering null (routing complete)");
  return null;
};

export default ChatRouter;