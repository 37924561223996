import React from "react";
import { Box, IconButton, Typography, Card } from "@mui/material";
import { ThumbUpOutlined, ShareOutlined, RemoveRedEyeOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface PostCardProps {
  title: string;
  author: string;
  character: string;
  timeAgo: string;
  likes: number;
  views: number;
  shares: number;
  exportId: string;  // Added exportId prop
  thumbnail?: string;
  onLike: () => void;
  onShare?: () => void;
}

export function PostCard({
                           title,
                           author,
                           character,
                           timeAgo,
                           likes,
                           views,
                           shares,
                           exportId,   // Added exportId
                           thumbnail,
                           onLike,
                           onShare
                         }: PostCardProps) {
  const navigate = useNavigate();

  const handleShare = async () => {
    try {
      const shareUrl = `${window.location.origin}/export/${exportId}`;
      await navigator.clipboard.writeText(shareUrl);
      toast.success("Link copied to clipboard!");
      onShare?.();
    } catch (error) {
      toast.error("Failed to copy link");
      console.error("Share error:", error);
    }
  };

  const handleClick = () => {
    navigate(`/export/${exportId}`);
  };

  return (
    <Card
      onClick={handleClick}
      sx={{
        mb: 2,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        borderRadius: '12px',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          p: 2,
          alignItems: 'flex-start',
          gap: 2,
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Box sx={{ mb: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography
                variant="body2"
                sx={{
                  color: 'rgba(255, 255, 255, 0.5)',
                  fontSize: '0.875rem'
                }}
              >
                {timeAgo}
              </Typography>
            </Box>
            <Typography
              variant="h6"
              sx={{
                color: 'white',
                fontSize: '1.25rem',
                fontWeight: 500,
                textAlign: 'left'
              }}
            >
              {title}
            </Typography>
          </Box>

          <Typography
            variant="body2"
            sx={{
              color: 'rgba(255, 255, 255, 0.5)',
              fontSize: '0.875rem',
              mb: 1,
              textAlign: 'left'
            }}
          >
            by {author} and {character}
          </Typography>

          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'rgba(255, 255, 255, 0.7)',
                '&:hover': { color: '#ff6b00' }
              }}
              onClick={(e) => {
                e.stopPropagation();
                onLike();
              }}
            >
              <IconButton
                size="small"
                sx={{
                  p: 0.5,
                  color: 'inherit'
                }}
              >
                <ThumbUpOutlined fontSize="small" />
              </IconButton>
              <Typography
                variant="body2"
                sx={{ ml: 0.5 }}
              >
                {likes}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'rgba(255, 255, 255, 0.7)'
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <RemoveRedEyeOutlined
                fontSize="small"
                sx={{ mr: 0.5 }}
              />
              <Typography variant="body2">
                {views}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'rgba(255, 255, 255, 0.7)',
                '&:hover': { color: '#ff6b00' }
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleShare();
              }}
            >
              <IconButton
                size="small"
                sx={{
                  p: 0.5,
                  color: 'inherit'
                }}
              >
                <ShareOutlined fontSize="small" />
              </IconButton>
              <Typography
                variant="body2"
                sx={{ ml: 0.5 }}
              >
                {shares}
              </Typography>
            </Box>
          </Box>
        </Box>

        {thumbnail && (
          <Box
            component="img"
            src={thumbnail}
            alt=""
            sx={{
              width: 64,
              height: 64,
              objectFit: "cover",
              borderRadius: 2,
            }}
          />
        )}
      </Box>
    </Card>
  );
}