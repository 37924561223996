import { useCallback, useEffect, useRef } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { incrementView } from "../../api/apiClient";
import log from "loglevel";
import * as Sentry from "@sentry/react";

// Set log level based on environment
log.setLevel(process.env.NODE_ENV === "development" ? log.levels.DEBUG : log.levels.INFO);

/**
 * Custom Hook to track views on the /export/:exportId route.
 */
const useExportViewTracker = () => {
  const location = useLocation();
  const processedExportsRef = useRef<Set<string>>(new Set());

  const trackView = useCallback(async (exportId: string) => {
    try {
      log.info(`[ViewTracker] Starting view increment for: ${exportId}`);
      console.group(`View Tracking: ${exportId}`);

      // Get stored views
      const rawViewed = sessionStorage.getItem("viewedExports");
      log.debug("[ViewTracker] Current storage state:", rawViewed);

      const viewedExports = JSON.parse(rawViewed || "{}");

      if (!viewedExports[exportId] && !processedExportsRef.current.has(exportId)) {
        log.info(`[ViewTracker] New view detected for: ${exportId}`);
        processedExportsRef.current.add(exportId);

        try {
          const response = await incrementView(exportId);
          log.debug("[ViewTracker] API Response:", response);

          // Update storage
          viewedExports[exportId] = true;
          sessionStorage.setItem("viewedExports", JSON.stringify(viewedExports));

          log.info(`[ViewTracker] Successfully tracked view for: ${exportId}`);
        } catch (apiError) {
          Sentry.captureException(apiError);
          log.error("[ViewTracker] API call failed:", apiError);
          // Remove from processed set on failure to allow retry
          processedExportsRef.current.delete(exportId);
          throw apiError;
        }
      } else {
        log.info(`[ViewTracker] View already tracked for: ${exportId}`);
        log.debug("[ViewTracker] Processed exports:", Array.from(processedExportsRef.current));
      }
    } catch (error) {

      Sentry.captureException(error);
      log.error("[ViewTracker] Processing error:", error);
      console.error("[ViewTracker] Detailed error:", {
        exportId,
        error: error instanceof Error ? error.message : "Unknown error",
        pathname: location.pathname
      });
    } finally {
      console.groupEnd();
    }
  }, [location.pathname]);

  useEffect(() => {
    const match = matchPath("/export/:exportId", location.pathname);
    log.debug("[ViewTracker] Route match:", match);

    if (match?.params.exportId) {
      trackView(match.params.exportId);
    }
  }, [location.pathname, trackView]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      log.debug("[ViewTracker] Cleaning up view tracker");
      processedExportsRef.current.clear();
    };
  }, []);
};

export default useExportViewTracker;