import React, { useEffect, useRef } from "react";

interface AudioWaveformProps {
  isAgentSpeaking: boolean;
  audioData: Float32Array;
}

const AudioWaveform: React.FC<AudioWaveformProps> = ({ isAgentSpeaking, audioData }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const animationRef = useRef<number | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const drawWaveform = () => {
      const WIDTH = canvas.width;
      const HEIGHT = canvas.height;

      ctx.clearRect(0, 0, WIDTH, HEIGHT);
      ctx.lineWidth = 2;
      ctx.strokeStyle = isAgentSpeaking ? "rgb(64,83,64)" : "rgb(139,72,72)";
      ctx.beginPath();

      const sliceWidth = WIDTH * 1.0 / audioData.length;
      let x = 0;

      for (let i = 0; i < audioData.length; i++) {
        const v = audioData[i];
        const y = (v + 1) * HEIGHT / 2;

        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      ctx.lineTo(WIDTH, HEIGHT / 2);
      ctx.stroke();

      animationRef.current = requestAnimationFrame(drawWaveform);
    };

    drawWaveform();

    return () => {
      if (animationRef.current !== null) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isAgentSpeaking, audioData]);

  return <canvas ref={canvasRef} width={300} height={50} />;
};

export default AudioWaveform;