import React from "react";
import { Helmet } from "react-helmet";

type SEOProps = {
  title: string;
  description: string;
  url: string;
  image: string;
};

const SEO: React.FC<SEOProps> = ({ title, description, url, image }) => (
  <Helmet>
    {/* Standard SEO Tags */}
    <title>{title}</title>
    <meta name="description" content={description} />

    {/* Open Graph Tags */}
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:url" content={url} />
    <meta property="og:image" content={image} />
    <meta property="og:type" content="website" />

    {/* Twitter Card Tags */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />

    {/* Schema.org Markup */}
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "http://schema.org",
        "@type": "WebSite",
        "name": title,
        "url": url,
        "image": image,
        "description": description
      })}
    </script>
    <meta name="author" content="securiter" />
  </Helmet>
);

export default SEO;
