// Function to save blob to localStorage with a unique ID
import { getWithExpiry, setWithExpiry } from "./storageUtils";
import log from "loglevel";
import * as Sentry from "@sentry/react";

export const saveBlobToLocalStorage = (audioUrl: string, blob: Blob) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    const base64data = reader.result as string;
    setWithExpiry(`audio_${audioUrl}`, base64data, 24 * 60 * 60 * 1000); // Cache for 24 hours
  };
  reader.readAsDataURL(blob);
};

export const getBlobFromLocalStorage = (audioUrl: string): Blob | null => {
  const base64String = getWithExpiry(`audio_${audioUrl}`);
  if (base64String && typeof base64String === "string") {
    try {
      // Split the data URL to separate the MIME type and the base64 data
      const [header, base64Data] = base64String.split(",");
      const mimeType = header.split(":")[1].split(";")[0];

      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mimeType });
    } catch (error) {
      Sentry.captureException(error);
      log.error("Error decoding blob from localStorage:", error);
      return null;
    }
  }
  return null;
};

export const blobToBase64 = (blob: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};
