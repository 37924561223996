import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faHouse, faHouseUser, faUser } from "@fortawesome/free-solid-svg-icons";
import { faComments as faCommentsRegular, faUser as faUserRegular } from "@fortawesome/free-regular-svg-icons";
import "../../css/menuBar.css";
import { getWithoutExpiry, RECENT_CHARACTERS_KEY } from "../../helper/storageUtils";
import { Message } from "../chat/InnerChat";
import { Cast } from "../../types/CastType";
import { Movie } from "../../types/MovieType";

const BottomMenuBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activePath, setActivePath] = useState("/search");

  const [messages, setMessages] = useState<{ [key: string]: Message[] }>({});

  const getMessagesFromLocalStorage = () => {
    const messagesJson = getWithoutExpiry("messages");
    if (messagesJson) {
      setMessages(JSON.parse(messagesJson));
    } else {
      setMessages({});
    }
  };

  const getTimeAgo = (timestamp: string | undefined): string => {
    if (!timestamp) return "Unknown time ago";

    const now = new Date();
    const messageTime = new Date(timestamp);
    const diffInMinutes = Math.floor((now.getTime() - messageTime.getTime()) / (1000 * 60));

    if (diffInMinutes < 1) {
      return "Just now";
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} minute${diffInMinutes !== 1 ? "s" : ""} ago`;
    } else if (diffInMinutes < 1440) { // Less than 24 hours
      const hours = Math.floor(diffInMinutes / 60);
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else {
      const days = Math.floor(diffInMinutes / 1440);
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    }
  };

  const historyToShow = Object.entries(messages)
    .sort(([, messagesA], [, messagesB]) => {
      const lastMessageA = messagesA[messagesA.length - 1];
      const lastMessageB = messagesB[messagesB.length - 1];
      const timestampA = lastMessageA?.timestamp ? new Date(lastMessageA.timestamp).getTime() : 0;
      const timestampB = lastMessageB?.timestamp ? new Date(lastMessageB.timestamp).getTime() : 0;
      return timestampB - timestampA;
    })
    .map(([character, characterMessages]) => {
      const lastMessage = characterMessages[characterMessages.length - 1];
      return {
        character,
        lastMessageTime: lastMessage?.timestamp ? getTimeAgo(lastMessage.timestamp) : "Unknown",
        profileImage: lastMessage?.profileImage || `${process.env.PUBLIC_URL}/Placeholder-Portrait.jpg`
      };
    })
    .slice(0, undefined);


  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  useEffect(() => {
    getMessagesFromLocalStorage();
  }, []);

  const handleNavigation = (path: string, state?: any) => {
    if (path === "/chat") {
      const recentCharacters = getWithoutExpiry(RECENT_CHARACTERS_KEY) || [];

      const sortedCharacters = historyToShow.map(({ character, lastMessageTime, profileImage }) => {
        const foundCharacter = recentCharacters.find((c: Cast) => c.character === character);

        if (foundCharacter) {
          return {
            ...foundCharacter,
            lastInteractionTimestamp: messages[character]?.[messages[character].length - 1]?.timestamp,
            lastMessageTime,
            profileImage
          };
        } else {
          // Fallback if character is not found in recentCharacters
          return {
            name: character,
            profile_path: profileImage,
            character: character,
            from_movie: "Unknown",
            lastInteractionTimestamp: messages[character]?.[messages[character].length - 1]?.timestamp,
            lastMessageTime,
            profileImage,
            isFromMessages: true
          } as Cast;
        }
      });

      const navState = {
        state: {
          chat_type: "history",
          movie: {
            title: "Recent Chats",
            cast: sortedCharacters,
            poster_path: "",
            id: 0,
            adult: false,
            backdrop_path: "",
            genre_ids: [],
            original_language: "",
            original_title: "",
            overview: "",
            popularity: 0,
            release_date: "",
            video: false,
            vote_average: 0,
            vote_count: 0,
            random_index: 0,
            source: "messages"
          } as Movie,
          cast: sortedCharacters
        }
      };
      navigate(path, navState);
    } else {
      navigate(path, { state });
    }
  };

  return (
    <div className="bottom-menu-bar">
      {[
        { path: "/search", icon: faHouse, activeIcon: faHouseUser, state: { from: "home" } },
        { path: "/chat", icon: faCommentsRegular, activeIcon: faComments, state: { chat_type: "popular" } },
        { path: "/profile", icon: faUserRegular, activeIcon: faUser, state: { userId: 123 } }
      ].map(({ path, icon, activeIcon, state }) => (
        <div
          key={path}
          className={`menu-item ${activePath === path ? "active" : ""}`}
          onClick={() => handleNavigation(path, state)}
          style={{ opacity: activePath === path ? 1 : 0.5 }}
        >
          <FontAwesomeIcon icon={activePath === path ? activeIcon : icon} />
        </div>
      ))}
    </div>
  );
};
export default BottomMenuBar;
