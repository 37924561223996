import { VAPI_ASSISTANT_ID, VAPI_PUBLIC_KEY } from "../../config";
import Vapi from "@vapi-ai/web";
import ScaleLoader from "react-spinners/ScaleLoader";
import React, { useCallback, useEffect, useState } from "react";
import log from "loglevel";

const vapi = new Vapi(VAPI_PUBLIC_KEY);
const vapi_assistant_id = VAPI_ASSISTANT_ID;


const VoiceChatVapi: React.FC = () => {
  const [connecting, setConnecting] = useState<boolean>(false);
  const [connected, setConnected] = useState<boolean>(false);
  const [assistantIsSpeaking, setAssistantIsSpeaking] = useState<boolean>(false);
  const [volumeLevel, setVolumeLevel] = useState<number>(0);
  const { TranscriptDisplay, resetTranscript } = useTranscriptDisplay();

  const { showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage } = usePublicKeyInvalid();

  useEffect(() => {
    vapi.on("call-start", () => {
      setConnecting(false);
      setConnected(true);
      setShowPublicKeyInvalidMessage(false);
    });

    vapi.on("call-end", () => {
      setConnecting(false);
      setConnected(false);
      setShowPublicKeyInvalidMessage(false);
      resetTranscript();
    });

    vapi.on("speech-start", () => {
      setAssistantIsSpeaking(true);
    });

    vapi.on("speech-end", () => {
      setAssistantIsSpeaking(false);
    });

    vapi.on("volume-level", (level) => {
      setVolumeLevel(level);
    });

    vapi.on("error", (error) => {
      log.error(error);
      setConnecting(false);
      if (isPublicKeyMissingError({ vapiError: error })) {
        setShowPublicKeyInvalidMessage(true);
      }
    });

  }, [resetTranscript]);

  const startCallInline = () => {
    setConnecting(true);
    resetTranscript();
    vapi.start(vapi_assistant_id);
  };

  const endCall = () => {
    vapi.stop();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "20px",
        boxSizing: "border-box"
      }}
    >
      {!connected ? (
        <Button
          label="Call SCENEGPT"
          onClick={startCallInline}
          isLoading={connecting}
        />
      ) : (
        <>
          <ActiveCallDetail
            assistantIsSpeaking={assistantIsSpeaking}
            volumeLevel={volumeLevel}
            onEndCallClick={endCall}
          />
          <TranscriptDisplay />
        </>
      )}

      {showPublicKeyInvalidMessage ? <PleaseSetYourPublicKeyMessage /> : null}
    </div>
  );
};

interface TranscriptMessage {
  type: string;
  role: "user" | "assistant";
  transcriptType: string;
  transcript: string;
}

const useTranscriptDisplay = () => {
  const [displayedTranscript, setDisplayedTranscript] = useState<TranscriptMessage[]>([]);
  const [pendingMessage, setPendingMessage] = useState<TranscriptMessage | null>(null);

  const resetTranscript = useCallback(() => {
    setDisplayedTranscript([]);
    setPendingMessage(null);
  }, []);

  useEffect(() => {
    const messageHandler = (msg: TranscriptMessage) => {
      if (msg.type !== "transcript" || msg.transcriptType !== "final") return;

      if (pendingMessage && pendingMessage.role === msg.role) {
        setPendingMessage({
          ...pendingMessage,
          transcript: pendingMessage.transcript + " " + msg.transcript
        });
      } else {
        if (pendingMessage) {
          setDisplayedTranscript(prev => [...prev, pendingMessage]);
        }
        setPendingMessage(msg);
      }
    };

    vapi.on("message", messageHandler);

    return () => {
      vapi.off("message", messageHandler);
    };
  }, [pendingMessage]);

  const finalizePendingMessage = useCallback(() => {
    if (pendingMessage) {
      setDisplayedTranscript(prev => [...prev, pendingMessage]);
      setPendingMessage(null);
    }
  }, [pendingMessage]);

  useEffect(() => {
    const timer = setTimeout(finalizePendingMessage, 2000);
    return () => clearTimeout(timer);
  }, [pendingMessage, finalizePendingMessage]);

  const TranscriptDisplay: React.FC = () => (
    <div style={{ maxHeight: "50vh", overflowY: "auto", width: "100%" }}>
      {displayedTranscript.map((msg, index) => (
        <div key={index} style={{ marginBottom: "10px" }}>
          <strong>{msg.role}:</strong> {msg.transcript}
        </div>
      ))}
      {pendingMessage && (
        <div style={{ marginBottom: "10px" }}>
          <strong>{pendingMessage.role}:</strong> {pendingMessage.transcript}
        </div>
      )}
    </div>
  );

  return { TranscriptDisplay, resetTranscript };
};

export const isPublicKeyMissingError = ({ vapiError }: { vapiError: any }): boolean => {
  return !!vapiError && vapiError.error.statusCode === 403 && vapiError.error.error === "Forbidden";
};


const Button: React.FC<{ label: string; onClick: () => void; isLoading?: boolean; disabled?: boolean }> = ({
                                                                                                             label,
                                                                                                             onClick,
                                                                                                             isLoading,
                                                                                                             disabled
                                                                                                           }) => {

  const opacity = disabled ? 0.75 : 1;
  const cursor = disabled ? "not-allowed" : "pointer";

  const Contents = isLoading ? (
    <ScaleLoader
      color="#000"
      height={10}
      width={2.5}
      margin={0.5}
      loading={true}
    />
  ) : (
    <p style={{ margin: 0, padding: 0 }}>{label}</p>
  );

  return (
    <button
      onClick={onClick}
      style={{
        backgroundColor: "white",
        color: "black",
        border: "2px solid #ddd",
        borderRadius: "8px",
        padding: "8px 20px",
        fontSize: "16px",
        outline: "none",
        boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
        transition: "all 0.3s ease",
        opacity,
        cursor
      }}
    >
      {Contents}
    </button>
  );
};

const numBars = 10;

const VolumeLevel: React.FC<{ volume: number }> = ({ volume }) => {
  return (
    <div style={{ padding: "20px" }}>
      <div style={{ color: "white", marginBottom: "8px" }}>
        <p>Volume Level:</p>
      </div>
      <div style={{ display: "flex", marginBottom: "10px" }}>
        {Array.from({ length: numBars }, (_, i) => (
          <div
            key={i}
            style={{
              width: "20px",
              height: "20px",
              margin: "2px",
              backgroundColor: i / numBars < volume ? "#3ef07c" : "white",
              borderRadius: "2px"
            }}
          />
        ))}
      </div>
      <div style={{ color: "white" }}>{volume}</div>
    </div>
  );
};


const AssistantSpeechIndicator: React.FC<{ isSpeaking: boolean }> = ({ isSpeaking }) => {

  return (
    <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
      <div
        style={{
          width: "20px",
          height: "20px",
          backgroundColor: isSpeaking ? "#3ef07c" : "#f03e3e",
          marginRight: "10px",
          borderRadius: "4px"
        }}
      />
      <p style={{ color: "white", margin: 0 }}>
        {isSpeaking ? "Assistant speaking" : "Assistant not speaking"}
      </p>
    </div>
  );
};


const ActiveCallDetail: React.FC<{
  assistantIsSpeaking: boolean;
  volumeLevel: number;
  onEndCallClick: () => void
}> = ({ assistantIsSpeaking, volumeLevel, onEndCallClick }) => {

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "15px",
          border: "1px solid #ddd",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
          width: "400px",
          height: "200px"
        }}
      >
        <AssistantSpeechIndicator isSpeaking={assistantIsSpeaking} />
        <VolumeLevel volume={volumeLevel} />
      </div>
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <Button label="End Call" onClick={onEndCallClick} />
      </div>
    </div>
  );
};

const usePublicKeyInvalid = () => {
  const [showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage] = useState<boolean>(false);


  // close public key invalid message after delay
  useEffect(() => {
    if (showPublicKeyInvalidMessage) {
      setTimeout(() => {
        setShowPublicKeyInvalidMessage(false);
      }, 3000);
    }
  }, [showPublicKeyInvalidMessage]);

  return {
    showPublicKeyInvalidMessage,
    setShowPublicKeyInvalidMessage
  };
};

const PleaseSetYourPublicKeyMessage: React.FC = () => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: "25px",
        left: "25px",
        padding: "10px",
        color: "#fff",
        backgroundColor: "#f03e3e",
        borderRadius: "5px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.2)"
      }}
    >
      Is your Vapi Public Key missing? (recheck your code)
    </div>
  );
};


export default VoiceChatVapi;