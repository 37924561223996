import { useCallback, useEffect, useRef, useState } from "react";
import { fetchNewMessages, fetchSingleConversation, fetchUserTokens } from "../api/apiClient";
import {
  getWithoutExpiry,
  RECENT_CHARACTERS_KEY,
  removeAdminMessagesFromLocalStorage,
  setWithoutExpiry
} from "../helper/storageUtils";
import log from "loglevel";
import { FrontendMessage, NewMessagesResponse } from "../types/NewMessageResponse";
import { Cast } from "../types/CastType";
import * as Sentry from "@sentry/react";

const LAST_CHECK_KEY = "lastBackgroundSyncCheck";

async function computeHash(str: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(str);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
}

interface UseBackgroundSyncProps {
  manualSyncOnly: boolean;
  manualSyncPerToken?: string;
}


export function useBackgroundSync({ manualSyncOnly, manualSyncPerToken }: UseBackgroundSyncProps) {
  const [sessionTokens, setSessionTokens] = useState<Record<string, string>>({});
  const [conversationHashes, setConversationHashes] = useState<Record<string, string>>({});
  const [isSyncing, setIsSyncing] = useState(false);

  const hasInitializedRef = useRef(false);
  const isSyncingRef = useRef(isSyncing);

  const local_log = (message: string, ...args: any[]) => {
    log.info(`[BackgroundSync] ${message}`, ...args);
  };

  const getStoredConversationHashes = useCallback(async () => {
    local_log("Computing conversation hashes");
    const messagesJson = getWithoutExpiry("messages");

    if (!messagesJson || typeof messagesJson !== "string") {
      local_log("No messages found in localStorage or invalid format");
      return {};
    }

    try {
      const parsedMessages = JSON.parse(messagesJson);
      const hashes: Record<string, string> = {};

      for (const [character, messages] of Object.entries(parsedMessages)) {
        if (Array.isArray(messages) && messages.length > 0) {
          const lastMessage = messages[messages.length - 1];
          if (lastMessage && typeof lastMessage.content === "string") {
            hashes[character] = await computeHash(lastMessage.content);
            local_log(`Computed hash for ${character}'s last message:`, hashes[character]);
          } else {
            local_log(`Invalid last message format for ${character}:`, lastMessage);
          }
        } else {
          local_log(`No messages or invalid format for ${character}`);
        }
      }

      local_log("Computed conversation hashes", hashes);
      return hashes;
    } catch (error) {
      Sentry.captureException(error);
      console.error("[BackgroundSync] Error parsing messages:", error);
      return {};
    }
  }, []);

  const getStoredSessionTokens = useCallback(() => {
    local_log("Getting stored session tokens");
    const tokens: Record<string, string> = {};

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith("X-Session-Token-")) {
        const characterName = key.replace("X-Session-Token-", "");
        const tokenData = getWithoutExpiry(key);

        if (tokenData && typeof tokenData === "string") {
          tokens[characterName] = tokenData;
          local_log(`Token for ${characterName}:`, tokenData);
        } else {
          local_log(`No valid token found for ${characterName}`);
        }
      }
    }

    local_log("Retrieved session tokens", tokens);
    return tokens;
  }, []);


  const manualSyncSingle = useCallback(async (sessionToken: string) => {
    if (isSyncingRef.current) {
      local_log("Sync already in progress, skipping");
      return;
    }

    setIsSyncing(true);
    try {
      const currentHashes = await getStoredConversationHashes();
      local_log("Syncing for session token:", sessionToken);

      // Fetch conversation data without checking for a local character match
      const result = await fetchSingleConversation(sessionToken, "");

      if (Object.keys(result).length === 0) {
        local_log("No data found for the given session token");
        return;
      }

      const [characterName, data] = Object.entries(result)[0];
      local_log(`Received data for character: ${characterName}`);

      // Update the local session token storage
      setWithoutExpiry(`X-Session-Token-${characterName}`, sessionToken);
      setSessionTokens(prev => ({
        ...prev,
        [characterName]: sessionToken
      }));

      if (data.hash !== currentHashes[characterName] && Array.isArray(data.conversation) && data.conversation.length > 0) {
        const storedMessages = getWithoutExpiry("messages");
        const parsedStoredMessages: Record<string, FrontendMessage[]> = storedMessages ? JSON.parse(storedMessages) : {};

        const newMessages: FrontendMessage[] = data.conversation.map(message => ({
          sender: message.role === "assistant" ? "character" : "user",
          content: message.content || "",
          message_id: message.hash || "",
          timestamp: message.timestamp || new Date().toISOString(),
          profileImage: data.character_image_url || ""
        }));

        parsedStoredMessages[characterName] = newMessages;

        const validatedMessages = validateMessages(parsedStoredMessages);
        setWithoutExpiry("messages", JSON.stringify(validatedMessages));

        setConversationHashes(prev => ({
          ...prev,
          [characterName]: data.hash
        }));

        // Update recent characters
        const recentCharacters: Cast[] = getWithoutExpiry(RECENT_CHARACTERS_KEY) || [];
        const existingIndex = recentCharacters.findIndex(c => c.name === characterName);
        if (existingIndex !== -1) {
          const [existingCharacter] = recentCharacters.splice(existingIndex, 1);
          existingCharacter.profile_path = data.character_image_url || existingCharacter.profile_path;
          recentCharacters.unshift(existingCharacter);
        } else {
          recentCharacters.unshift({
            id: 0,
            name: characterName,
            character: characterName,
            from_movie: data.series || "Unknown",
            profile_path: data.character_image_url || ""
          });
        }
        setWithoutExpiry(RECENT_CHARACTERS_KEY, recentCharacters);

        local_log(`Updated messages and recent characters for ${characterName}`);
      } else {
        local_log(`No updates needed for ${characterName}`);
      }

      local_log("Manual sync for single token completed successfully");
    } catch (error) {
      Sentry.captureException(error);
      console.error("[BackgroundSync] Error during manual sync for single token:", error);
    } finally {
      setIsSyncing(false);
    }
  }, [fetchSingleConversation, getStoredConversationHashes, setSessionTokens]);


  const fetchAndStoreUserTokens = useCallback(async () => {
    local_log("Fetching user tokens from backend");

    try {
      const backendTokens = await fetchUserTokens();
      local_log("Received tokens from backend", backendTokens);

      Object.entries(backendTokens).forEach(([character, token]) => {
        setWithoutExpiry(`X-Session-Token-${character}`, token);
      });

      setSessionTokens((prevTokens) => ({
        ...prevTokens,
        ...backendTokens
      }));

      local_log("Updated session tokens with backend data");
    } catch (error) {
      Sentry.captureException(error);
      console.error("[BackgroundSync] Error fetching user tokens:", error);
    }
  }, []);

  const checkForNewMessages = useCallback(async () => {
    removeAdminMessagesFromLocalStorage();

    local_log("Checking for new messages");
    const charactersWithUpdates: string[] = [];
    const currentTokens = getStoredSessionTokens();
    const currentHashes = await getStoredConversationHashes();
    local_log("Current session tokens:", currentTokens);
    local_log("Current conversation hashes:", currentHashes);

    // if (Object.keys(currentTokens).length === 0) {
    //   local_log("No session tokens available, skipping check");
    //   return;
    // }

    try {
      local_log("Fetching new messages", { currentTokens, currentHashes });
      const result: NewMessagesResponse = await fetchNewMessages(currentTokens, currentHashes);
      local_log("Received new messages response", result);

      let hasUpdates = false;
      const updatedHashes: Record<string, string> = {};
      const updatedMessages: Record<string, FrontendMessage[]> = {};
      const characterImages: Record<string, string> = {};

      const storedMessages = getWithoutExpiry("messages");
      let parsedStoredMessages: Record<string, FrontendMessage[]> = {};
      if (storedMessages) {
        try {
          parsedStoredMessages = JSON.parse(storedMessages);
        } catch (error) {
          Sentry.captureException(error);
          console.error("[BackgroundSync] Error parsing stored messages:", error);
          parsedStoredMessages = {};
        }
      }

      const profileImages: Record<string, string> = {};

      for (const [character, messages] of Object.entries(parsedStoredMessages)) {
        if (Array.isArray(messages) && messages.length > 0 && messages[0].profileImage) {
          profileImages[character] = messages[0].profileImage;
        }
      }

      for (const [character, data] of Object.entries(result)) {
        local_log(`Checking updates for character: ${character}`, data);
        if (data.hash !== currentHashes[character] && Array.isArray(data.conversation) && data.conversation.length > 0) {
          const existingMessages = Array.isArray(parsedStoredMessages[character]) ? parsedStoredMessages[character] : [];

          const newMessages: FrontendMessage[] = data.conversation.map(message => ({
            sender: message.role === "assistant" ? "character" : "user",
            content: message.content || "",
            message_id: message.hash || "",
            timestamp: message.timestamp || new Date().toISOString(),
            profileImage: data.character_image_url || profileImages[character] || ""
          }));

          if (existingMessages.length === 0) {
            local_log(`No existing messages for character ${character}, adding all messages`);
            updatedHashes[character] = data.hash;
            updatedMessages[character] = newMessages;
            hasUpdates = true;
          } else {
            const lastExistingMessageId = existingMessages[existingMessages.length - 1].message_id;
            const newMessageIndex = newMessages.findIndex(msg => msg.message_id === lastExistingMessageId);

            if (newMessageIndex === -1) {
              updatedHashes[character] = data.hash;
              updatedMessages[character] = newMessages;
              hasUpdates = true;
            } else if (newMessageIndex < newMessages.length - 1) {
              updatedHashes[character] = data.hash;
              updatedMessages[character] = newMessages.slice(newMessageIndex + 1);
              hasUpdates = true;
            } else {
              local_log(`No new messages for character ${character}, only hash changed`);
            }
          }

          characterImages[character] = data.character_image_url || profileImages[character] || "";
        } else {
          local_log(`No updates for character ${character}`);
        }
      }

      if (hasUpdates) {
        local_log("Updates found, updating state", { updatedHashes, updatedMessages });
        setConversationHashes(prev => {
          const newHashes = { ...prev, ...updatedHashes };
          local_log("Updated conversation hashes", newHashes);
          return newHashes;
        });

        if (Object.keys(updatedMessages).length > 0) {
          const newMessages = { ...parsedStoredMessages };
          for (const [character, messages] of Object.entries(updatedMessages)) {
            if (!Array.isArray(newMessages[character])) {
              newMessages[character] = [];
            }
            newMessages[character] = [...newMessages[character], ...messages];
            charactersWithUpdates.push(character);
          }

          const validatedMessages = validateMessages(newMessages);
          setWithoutExpiry("messages", JSON.stringify(validatedMessages));
          local_log("Updated messages in storage", validatedMessages);

          const recentCharacters: Cast[] = getWithoutExpiry(RECENT_CHARACTERS_KEY) || [];
          charactersWithUpdates.forEach(character => {
            const existingIndex = recentCharacters.findIndex(c => c.name === character);
            if (existingIndex !== -1) {
              const [existingCharacter] = recentCharacters.splice(existingIndex, 1);
              existingCharacter.profile_path = characterImages[character] || existingCharacter.profile_path;
              recentCharacters.unshift(existingCharacter);
            } else {
              recentCharacters.unshift({
                id: 0,
                name: character,
                character: character,
                from_movie: result[character].series || "Unknown",
                profile_path: characterImages[character] || ""
              });
            }
          });
          setWithoutExpiry(RECENT_CHARACTERS_KEY, recentCharacters);
          local_log("Updated RECENT_CHARACTERS_KEY", recentCharacters);
        }
      } else {
        local_log("No updates found");
      }

      const lastCheckTime = Date.now();
      setWithoutExpiry(LAST_CHECK_KEY, lastCheckTime);
      local_log("Updated last check time", lastCheckTime);
    } catch (error) {
      Sentry.captureException(error);
      console.error("[BackgroundSync] Error checking for new messages:", error);
    }
  }, [getStoredSessionTokens, getStoredConversationHashes]);

  useEffect(() => {
    isSyncingRef.current = isSyncing;
  }, [isSyncing]);

  function validateMessages(messages: Record<string, any>): Record<string, FrontendMessage[]> {
    const validatedMessages: Record<string, FrontendMessage[]> = {};

    for (const [character, messageList] of Object.entries(messages)) {
      if (Array.isArray(messageList)) {
        validatedMessages[character] = messageList.filter(message =>
          typeof message === "object" &&
          message !== null &&
          typeof message.sender === "string" &&
          typeof message.content === "string" &&
          typeof message.message_id === "string" &&
          typeof message.timestamp === "string" &&
          typeof message.profileImage === "string"
        );
      } else {
        validatedMessages[character] = [];
      }
    }

    return validatedMessages;
  }

  useEffect(() => {
    if (hasInitializedRef.current || manualSyncOnly) {
      return;
    }

    const initializeData = async () => {
      hasInitializedRef.current = true;
      local_log("Initializing background sync");

      setTimeout(async () => {
        try {
          await fetchAndStoreUserTokens();
          const tokens = getStoredSessionTokens();
          const hashes = await getStoredConversationHashes();
          local_log("Initialized session tokens:", tokens);
          local_log("Initialized conversation hashes:", hashes);
          setSessionTokens(tokens);
          setConversationHashes(hashes);

          if (!manualSyncOnly) {
            await checkForNewMessages();
          }
        } catch (error) {
          Sentry.captureException(error);
          console.error("[BackgroundSync] Error during initialization:", error);
        }
      }, 0);
    };

    initializeData();
  }, [manualSyncOnly]);

  const updateSessionToken = useCallback((character: string, token: string) => {
    local_log(`Updating session token for ${character}`, token);
    setSessionTokens(prev => {
      const newTokens = { ...prev, [character]: token };
      setWithoutExpiry(`X-Session-Token-${character}`, token);
      local_log("Updated session tokens", newTokens);
      return newTokens;
    });
  }, []);

  const manualSync = useCallback(async () => {
    if (isSyncingRef.current) {
      local_log("Sync already in progress, skipping");
      return;
    }

    setIsSyncing(true);
    try {
      await fetchAndStoreUserTokens();
      await checkForNewMessages();
      local_log("Manual sync completed successfully");
    } catch (error) {
      Sentry.captureException(error);
      console.error("[BackgroundSync] Error during manual sync:", error);
      // throw error;
    } finally {
      setIsSyncing(false);
    }
  }, [checkForNewMessages]);

  const debugSessionTokens = useCallback(() => {
    local_log("Debugging session tokens");
    const recentCharactersData = getWithoutExpiry(RECENT_CHARACTERS_KEY);
    local_log("Recent characters:", JSON.stringify(recentCharactersData));

    if (Array.isArray(recentCharactersData)) {
      recentCharactersData.forEach((character: any, index: number) => {
        local_log(`Character ${index}:`, JSON.stringify(character));
        if (character && character.name) {
          const tokenKey = `X-Session-Token-${character.name}`;
          const token = getWithoutExpiry(tokenKey);
          local_log(`Token for ${character.name} (${tokenKey}):`, token);
        }
      });
    } else {
      local_log("Recent characters data is not an array");
    }
  }, []);

  return {
    updateSessionToken,
    sessionTokens,
    conversationHashes,
    manualSync,
    manualSyncSingle,
    isSyncing,
    debugSessionTokens
  };
}