import React from "react";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { Box, BoxProps, Container, List, ListItem, Typography } from "@mui/material";


const theme = createTheme({
  palette: {
    background: {
      default: "#121212"
    },
    text: {
      primary: "#ffffff"
    }
  },
  typography: {
    fontFamily: "-apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Oxygen, Ubuntu, Cantarell, \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif"
  }
});

interface ChatMessageProps extends BoxProps {
  isUser?: boolean;
}


const ChatMessagesContainer = styled(Box)(({ theme }) => ({
  accentColor: "auto",
  alignContent: "normal",
  alignItems: "normal",
  alignSelf: "auto",
  backgroundAttachment: "scroll",
  backgroundColor: "rgba(0, 0, 0, 0)",
  backgroundImage: "none",
  backgroundPosition: "0% 0%",
  backgroundRepeat: "repeat",
  backgroundSize: "auto",
  blockSize: "479.938px",
  boxSizing: "border-box",
  caretColor: "rgb(255, 255, 255)",
  color: "rgb(255, 255, 255)",
  columnGap: "normal",
  display: "block",
  fontFamily: theme.typography.fontFamily,
  fontSize: "16px",
  fontWeight: 400,
  height: "479.938px",
  inlineSize: "657px",
  lineHeight: "normal",
  overflowX: "hidden",
  overflowY: "auto",
  paddingBlockStart: "16.84px",
  paddingInlineStart: "8.42px",
  paddingLeft: "8.42px",
  paddingTop: "16.84px",
  position: "relative",
  rowGap: "normal",
  tabSize: 8,
  textAlign: "center",
  width: "657px",
  zIndex: "auto"
}));

const ChatMessage = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isUser"
})<ChatMessageProps>(({ theme, isUser }) => ({
  accentColor: "auto",
  alignItems: "flex-start",
  alignSelf: "flex-start",
  animation: "slideInFromBottom 0.5s forwards",
  backgroundColor: isUser ? "rgba(220, 247, 197, 0.9)" : "rgba(250, 250, 250, 0.953)",
  borderBottomLeftRadius: "0px",
  borderBottomRightRadius: "12.63px",
  borderTopLeftRadius: "12.63px",
  borderTopRightRadius: "12.63px",
  boxShadow: isUser ? "rgba(0, 0, 0, 0.1) 0px 4px 6px 0px" : "rgba(0, 0, 0, 0.075) 0px 0px 21.892px 0px",
  boxSizing: "border-box",
  caretColor: "rgb(51, 51, 51)",
  color: "rgb(51, 51, 51)",
  display: "flex",
  flexDirection: "column",
  fontFamily: "Inter-Medium, sans-serif",
  fontSize: "15.156px",
  fontWeight: 400,
  marginBlockEnd: isUser ? "16px" : "33.68px",
  marginBlockStart: "0px",
  marginBottom: isUser ? "16px" : "33.68px",
  marginInlineEnd: isUser ? "16px" : "65.7px",
  marginInlineStart: isUser ? "426.016px" : "8.541px",
  marginLeft: isUser ? "426.016px" : "8.541px",
  marginRight: isUser ? "16px" : "65.7px",
  maxInlineSize: "70%",
  maxWidth: "70%",
  paddingBlockEnd: "6.736px",
  paddingBlockStart: "6.736px",
  paddingBottom: "6.736px",
  paddingInlineEnd: "8.541px",
  paddingInlineStart: "8.541px",
  paddingLeft: "8.541px",
  paddingRight: "8.541px",
  paddingTop: "6.736px",
  position: "relative",
  transform: "matrix(1, 0, 0, 1, 0, 0)"
}));

const ReactionButtons = styled(Box)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: "rgba(250, 250, 250, 0.976)",
  borderBottomLeftRadius: "12px",
  borderBottomRightRadius: "12px",
  borderTopLeftRadius: "12px",
  borderTopRightRadius: "12px",
  bottom: "-20px",
  display: "flex",
  height: "18px",
  inlineSize: "70.9375px",
  left: "10px",
  position: "absolute",
  width: "70.9375px"
}));

const ReactionButton = styled("button")(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0)",
  border: "none",
  cursor: "pointer",
  display: "block",
  fontFamily: "Arial",
  fontSize: "14px",
  height: "18px",
  marginInlineEnd: "3px",
  marginInlineStart: "3px",
  opacity: 0.3,
  paddingBlockEnd: "1px",
  paddingBlockStart: "1px",
  paddingInlineEnd: "6px",
  paddingInlineStart: "6px",
  transform: "matrix(1.1, 0, 0, 1.1, 0, 0)",
  transition: "all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)",
  width: "29.4688px"
}));

const ExampleQuestionsList = styled(List)(({ theme }) => ({
  animation: "pop 0.5s ease-out forwards",
  blockSize: "33.6719px",
  display: "block",
  height: "33.6719px",
  inlineSize: "600.594px",
  left: "-25.26px",
  listStylePosition: "outside",
  listStyleType: "disc",
  marginBlockEnd: "42.1px",
  marginBlockStart: "16px",
  marginBottom: "42.1px",
  marginTop: "16px",
  paddingInlineStart: "40px",
  paddingLeft: "40px",
  position: "relative",
  right: "25.26px",
  width: "600.594px"
}));

const NewChat = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={false} disableGutters>
        <ChatMessagesContainer>
          <ChatMessage>
            <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>
            <ReactionButtons>
              <ReactionButton>👍</ReactionButton>
              <ReactionButton>👎</ReactionButton>
            </ReactionButtons>
          </ChatMessage>
          <ChatMessage isUser>
            <Typography>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Typography>
          </ChatMessage>
          <ChatMessage>
            <Typography>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat.</Typography>
            <ReactionButtons>
              <ReactionButton>👍</ReactionButton>
              <ReactionButton>👎</ReactionButton>
            </ReactionButtons>
          </ChatMessage>
          <ChatMessage isUser>
            <Typography>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
              pariatur.</Typography>
          </ChatMessage>
          <ExampleQuestionsList>
            <ListItem>
              <Typography>Lorem ipsum dolor sit amet?</Typography>
            </ListItem>
            <ListItem>
              <Typography>Consectetur adipiscing elit?</Typography>
            </ListItem>
          </ExampleQuestionsList>
        </ChatMessagesContainer>
      </Container>
    </ThemeProvider>
  );
};

export default NewChat;