import React, { FC, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons"; // Changed the icon to faList
import "../../assets/css/chatlengthbtn.scss";
import posthog from "posthog-js";
import CornerMaxButton from "../miscs/CornerMaxButton"; // Import the CSS file

// Define the properties for the ChatLengthButton component
interface ChatLengthProps {
  setResponseLength: (length: string) => void;
}

// Create the ChatLengthButton component
const ChatLengthButton: FC<ChatLengthProps> = ({ setResponseLength }) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedLength, setSelectedLength] = useState<string | null>(null);
  const lengths = ["Short", "Long"];
  const listRef = useRef<HTMLUListElement>(null);

  const handleClick = (e: React.FormEvent) => {
    e.preventDefault();
    setIsActive(!isActive);
    if (listRef.current) {
      listRef.current.style.height = isActive ? "0" : "300px";
    }
  };

  // Handle the click event for the length option
  const handleLengthClick = (length: string) => {
    setResponseLength(length);
    posthog.capture("length_selected", { length });
    setSelectedLength(length);
    setTimeout(() => {
      setIsActive(false);
    }, 300);
  };

  // Handle the click event outside the component
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target && !target.closest(".length-list-container")) {
        setIsActive(false);
        if (listRef.current) {
          listRef.current.style.height = "300px";
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Render the component
  return (
    <React.Fragment>
      <div className={`length-list-container ${isActive ? "active" : ""} side-options-buttons`}>
        <CornerMaxButton blockIsActive={false} location="options-menu">
          <button
            className="length-more-button"
            aria-label="Menu Button"
            onClick={handleClick}
          >

            <FontAwesomeIcon
              icon={faList}
              className="icon-shadow"
              style={{ color: "#f4f7fa" }}
            />{" "}

          </button>
        </CornerMaxButton>
        <ul className="length-more-button-list" ref={listRef} style={{
          display: isActive ? "block" : ""
        }}>
          {lengths.map((length, index) => (
            <CornerMaxButton blockIsActive={true} key={index} location="options-item">
              <li
                key={index}
                className={`length-more-button-list-item ${length === selectedLength ? "selected" : ""}`}
                onClick={() => handleLengthClick(length)}
              >
                <span>{length}</span>
              </li>
            </CornerMaxButton>
          ))}
        </ul>

      </div>
    </React.Fragment>
  );
};

// Export the ChatLengthButton component
export default ChatLengthButton;
