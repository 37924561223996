// In ModalContext.tsx
import React from "react";
import { AuthenticatedUser } from "../../helper/authHelper";

interface ModalContextProps {
  openModal: () => void;
  user: AuthenticatedUser | undefined;
}

export const ModalContext = React.createContext<ModalContextProps | null>(null);
