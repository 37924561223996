// Remove Sentry initialization from useEffect and export the ErrorBoundary directly
import React, { ReactNode } from "react";
import * as Sentry from "@sentry/react";
import { currentEnv } from "../config";

interface SentryProviderProps {
  children: ReactNode;
}

function SentryErrorBoundaryComponent({ children }: { children: ReactNode }): React.ReactElement {
  return <>{children}</>;
}

const SentryErrorBoundary = Sentry.withErrorBoundary(SentryErrorBoundaryComponent, {
  fallback: (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <h1 className="text-2xl font-bold text-gray-800 mb-4">
        An unexpected error has occurred
      </h1>
      <button
        onClick={() => window.location.reload()}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
      >
        Reload Page
      </button>
    </div>
  ),
  beforeCapture: (scope) => {
    scope.setTag("location", window.location.href);
    scope.setTag("environment", currentEnv);
  }
});

const SentryProvider: React.FC<SentryProviderProps> = ({ children }) => {
  return <SentryErrorBoundary>{children}</SentryErrorBoundary>;
};

export default SentryProvider;