import { MouseEventHandler, useState } from "react";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const Arrow = (props: {
  onClick: MouseEventHandler<SVGSVGElement> | undefined;
}) => {
  const [color, setColor] = useState("black");

  const handleClick = () => {
    setColor(color === "black" ? "red" : "black");
  };

  return (
    <FontAwesomeIcon
      icon={faAngleLeft}
      style={{
        color: "#FF8A00",
        width: "10px",
        cursor: "pointer",
        scale: "1.7"
      }}
      onClick={props.onClick}
    />
  );
};

Arrow.propTypes = {
  onClick: PropTypes.func
};
export default Arrow;
